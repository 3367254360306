import { Button, H3, Notification, P3 } from '@missionlane/compass-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { NotificationLevel } from '@missionlane/compass-ui/lib/typescript/components/Notification/types';
import {
  FieldType,
  InputMode,
  SecureForm,
  SecureFormProps,
  Validation,
  ValidationErrorOverrides,
} from '@core/components/SecureForm';
import cvv from '@core/assets/CVV.svg';
import ApiErrors from '@core/utils/ApiErrors';
import { cardSupportPhone } from '@core/utils/contact';
import { MLError, TrackService } from '@core/services';
import { CvvVerificationQueryVariables } from '@core/graphql/globalTypes';

interface CvvProps {
  onSuccess: (tokenizedCvv: string) => void;
  accountId: string;
}
interface ErrorMessage {
  message: string;
  level: NotificationLevel;
}
enum Field {
  CVV = 'CVV',
}

const rejectionMessage = 'Please enter 3 digits';
const numericOnlyReplacePattern = '/[^0-9]+/g';

const cvvValidator = /^\d{3}$/;

const validationErrorMessageOverrides: ValidationErrorOverrides = {
  [Validation.Regex]: rejectionMessage,
};

const cvvVerificationQuery = gql`
  query cvvVerification($accountId: String!, $tokenizedCvv: String!) {
    account(accountId: $accountId) {
      id
      cvvVerification(tokenizedCvv: $tokenizedCvv) {
        remainingSubmissions
        remainingSubmissionsText
        isLockedOut
        isCvvVerified
        tokenizedCvv
      }
    }
  }
`;

const cvvVerificationFormConfig: SecureFormProps['formConfig'] = {
  [Field.CVV]: {
    ariaLabel: 'Enter your CVV',
    autoFocus: true,
    hideValue: true,
    // Does not work when hideValue is toggled, open issue here:
    // https://github.com/verygoodsecurity/vgs-collect-js/issues/48
    inputMode: InputMode.Numeric,
    maxLength: 3,
    label: 'Enter your CVV',
    replacePattern: [numericOnlyReplacePattern],
    type: FieldType.Text,
    validations: [Validation.Required, `${cvvValidator}`],
    placeholder: 'Enter a 3-digit number',
  },
};

const Cvv = ({ onSuccess, accountId }: CvvProps) => {
  const formRef = useRef<SecureForm>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    TrackService.page('Change Phone Enter CVV');
  }, []);

  const handleUpdate = useCallback(() => {
    setIsDisabled(!formRef.current?.isReady || !formRef.current?.isValid);
  }, [formRef]);

  const handleSubmit = useCallback(() => {
    TrackService.click('Change Phone Enter CVV: Confirm');
    setIsSubmitting(true);
    formRef.current?.submit<CvvVerificationQueryVariables>({
      mutation: cvvVerificationQuery,
      variables: (formValues) => ({
        accountId,
        tokenizedCvv: formValues[Field.CVV],
      }),
      onSuccess: (status, response) => {
        setIsSubmitting(false);
        formRef.current?.focusOn(Field.CVV);

        const { errors, error, data } = response;
        const cvvVerification = data?.account?.cvvVerification;
        if (cvvVerification?.isCvvVerified) {
          onSuccess(cvvVerification.tokenizedCvv);
        } else {
          formRef.current?.focusOn(Field.CVV);
          if (error || errors?.length) {
            formRef.current?.reset?.();
            TrackService.message(
              'Change Phone Enter CVV Error Message: Unable to Verify',
            );
            setErrorMessage({
              message:
                'There was an unknown error while verifying your CVV, please try again. If you continue to experience this issue send us a message.',
              level: 'error',
            });
          } else {
            if (cvvVerification?.isLockedOut) {
              TrackService.message(
                'Change Phone Enter CVV Error Message: CVV Lockout Error',
              );
              setErrorMessage({
                message:
                  "For your security, we've blocked online account updates for 7 days. To change your phone number, give us a call at 855-790-8860",
                level: 'error',
              });
            } else if (cvvVerification?.remainingSubmissions > 0) {
              TrackService.message(
                'Change Phone Enter CVV Error Message: Attempt Warning',
              );
              setErrorMessage({
                message: cvvVerification?.remainingSubmissionsText,
                level: 'warning',
              });
            } else {
              TrackService.message(
                'Change Phone Enter CVV Error Message: Uncategorized Error',
              );
              setErrorMessage({
                message:
                  'We ran into a technical issue while verifying your CVV, please try again. If you continue to experience this issue send us a message.',
                level: 'error',
              });
            }
          }
        }
      },
      onError: (errors) => {
        setIsSubmitting(false);
        MLError.report({
          name: 'Modify PIN - onError',
          message: JSON.stringify(errors),
        });
        setErrorMessage({
          message:
            'There was an unknown error while verifying your CVV, please try again. If you continue to experience this issue send us a message.',
          level: 'error',
        });
      },
    });
  }, []);

  const onCancel = () => {
    TrackService.click('Change Phone Enter CVV: Cancel');
    navigate(-1);
  };

  return (
    <>
      <div className="flex flex-column">
        <H3 testID="ChangePhoneCvvHeader">Update phone number</H3>
        <P3>
          To change your phone number, verify the 3-digit CVV on the back of
          your card.
        </P3>
        <div className="flex flex-column mt2 mt4-ns pb5 justify-center items-center flex-row-ns w-100">
          <img src={cvv} alt="error" />
        </div>
        <SecureForm
          cName={process.env.REACT_APP_VGS_ACCOUNT_MANAGEMENT_CNAME}
          vaultId={process.env.REACT_APP_VGS_ACCOUNT_MANAGEMENT_VAULT_ID}
          formConfig={cvvVerificationFormConfig}
          onUpdate={handleUpdate}
          ref={formRef}
          validationErrorMessageOverrides={validationErrorMessageOverrides}
          trackName={'Change Phone Enter CVV: '}
        />
        {errorMessage && !isSubmitting && (
          <>
            {!errorMessage.message.includes('give us a call') && (
              <Notification variant="inline" level={errorMessage.level}>
                <ApiErrors
                  error={errorMessage.message}
                  linkText=" send us a message."
                  url="https://support.missionlane.com/hc/en-us/requests/new"
                  phone={cardSupportPhone}
                />
              </Notification>
            )}
            {errorMessage.message.includes('give us a call') && (
              <Notification variant="inline" level={errorMessage.level}>
                <ApiErrors
                  error={errorMessage.message}
                  linkText={cardSupportPhone}
                  phone={cardSupportPhone}
                />
              </Notification>
            )}
          </>
        )}
        <div className="flex flex-column mt4 justify-start-ns items-center-ns flex-row-ns">
          <div className="mr3-ns">
            <Button
              text="Confirm"
              onPress={handleSubmit}
              loading={isSubmitting}
              disabled={isDisabled}
            />
          </div>
          <Button text="Cancel" variant="text" onPress={onCancel} />
        </div>
      </div>
    </>
  );
};

export default Cvv;
