import { Button, Spacing } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import { confettiJson } from './utils/confetti';
import PageWrapper from '@core/components/Page/PageWrapper';
import useClipOfferV2 from '@clip/ClipProgress/hooks/useClipOfferV2';
import { useAccount } from '@core/components/Auth/AccountContext';

const MessageGroup = ({
  title,
  message,
}: {
  title: string;
  message: string;
}) => {
  return (
    <div>
      <h3 className="mb5">{title}</h3>
      <p className="mw6">{message}</p>
    </div>
  );
};

const StreakGroupSuccess = () => (
  <MessageGroup
    title="You’ve got a payment streak going!"
    message="Build your streak by making your payments on time each month."
  />
);

const TrackerGroupSuccess = () => (
  <MessageGroup
    title="You’ve unlocked a payment tracker"
    message="Check out your progress towards a potential credit limit increase."
  />
);

const PAYMENT_SUCCESS_STREAK_CONTENT: Record<string, () => JSX.Element> = {
  STREAK: StreakGroupSuccess,
  TRACKER: TrackerGroupSuccess,
} as const;

const PaymentSuccessScreen = () => {
  const navigate = useNavigate();
  const { accountId } = useAccount();
  const clipOffer = useClipOfferV2();

  const isTrackerEnrolled = clipOffer?.experience === 'TRACKER';
  const isFirstPayment = clipOffer?.currentPaymentStreak === null;
  const showTracker = isTrackerEnrolled && isFirstPayment;
  const Content = PAYMENT_SUCCESS_STREAK_CONTENT[clipOffer?.experience ?? ''];
  const buttonText = showTracker ? 'See Tracker' : 'See Streak';

  return (
    <PageWrapper>
      <Lottie
        animationData={confettiJson}
        autoPlay
        loop={true}
        style={{
          position: 'absolute',
          top: 0,
          height: `${Spacing.l}rem`,
          width: `${Spacing.l}rem`,
        }}
      />
      <div>
        <Content />
        <div className="flex">
          <Button
            text={buttonText}
            onPress={() => navigate(`/account/${accountId}/my-progress`)}
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default PaymentSuccessScreen;
