import { Notification, Spacer, H4 } from '@missionlane/compass-ui';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaymentConfirmationButtons } from '../PaymentConfirmationButtons';
import PaymentDisplay from '../PaymentDisplay';
import { PaymentMessage } from '../PaymentMessage';
import { PaymentMethodName } from '@payments/components/Pay/Pay';
import { useTracking } from '@core/services/TrackService/useTracking';

interface PaymentReviewProps {
  amount: number;
  acctLast4?: string;
  handleSubmit: () => void;
  submissionError: string | null;
}

export const PaymentReview: React.FC<PaymentReviewProps> = ({
  amount,
  acctLast4,
  handleSubmit,
  submissionError,
}) => {
  const navigate = useNavigate();
  const { trackError } = useTracking();

  useEffect(() => {
    if (submissionError) {
      trackError({
        name: 'Debit Card Payment Failure',
        feature: 'Make Debit Card Payment',
        metadata: {
          payFrom: 'DEBIT',
          selectAmount: amount,
          selectDate: 'IMMEDIATE',
          error: {
            displayMessage: submissionError,
            technicalMessage: submissionError,
            rawError: JSON.stringify(submissionError),
            expected: true,
          },
        },
        error: {
          code: 'PAY0003',
          message: submissionError,
          name: 'Debit Card Payment Failure',
        },
      });
    }
  }, [submissionError]);

  return (
    <>
      <div className="ph3 ph0-l">
        <H4>Review Payment Details</H4>
        <PaymentDisplay
          amount={amount}
          className="ml0 mv4"
          paymentMethod={PaymentMethodName.DEBIT_CARD}
          isImmediate
        />
        {submissionError ?
          <Notification level="error" variant="inline">
            <span>{submissionError}</span>
          </Notification>
        : <PaymentMessage cardLast4={acctLast4 || ''} isImmediatePayment />}
        <Spacer direction="vertical" size="xm" />
        <PaymentConfirmationButtons
          text={submissionError ? 'Edit Payment Details' : 'Submit Payment'}
          onSubmit={handleSubmit}
          onCancel={() => {
            navigate('..');
          }}
        />
      </div>
    </>
  );
};
