import { gql, useQuery } from '@apollo/client';
import { ColorNames, Fonts, P3 } from '@missionlane/compass-ui';
import ResponsiveTypography from './ResponsiveTypography';
import toTitleCase from '@core/utils/toTitleCase';
import { AddressQuery } from '@core/graphql/globalTypes';

export const ADDRESS_QUERY = gql`
  query Address {
    customer {
      contactInfo {
        address {
          street1
          street2
          city
          postalCode
          state
        }
      }
    }
  }
`;

interface CustomerAddressProps {
  className?: string;
  textColor?: ColorNames;
}

const CustomerAddress = ({ className, textColor }: CustomerAddressProps) => {
  const { loading, data } = useQuery<AddressQuery>(ADDRESS_QUERY);
  const { street1, street2, city, state, postalCode } =
    data?.customer?.contactInfo?.address || {};

  if (!street1 || !city || !state || !postalCode) {
    return null;
  }

  let street = toTitleCase(street1);

  if (street2) {
    street += ` ${toTitleCase(street2)}`;
  }

  return (
    <div className={className}>
      <ResponsiveTypography
        type="PARAGRAPH"
        mobileLevel="P1"
        desktopLevel="P3"
        color={textColor}
        style={{ fontFamily: Fonts.semiBold }}
      >
        {loading ?
          'Loading...'
        : <>
            {street}
            <br />
            {toTitleCase(city)}, {state} {postalCode}
          </>
        }
      </ResponsiveTypography>
    </div>
  );
};

export default CustomerAddress;
