import { Button } from '@missionlane/compass-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMakePayment } from './MakePaymentContext';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import { useLocalStorage } from '@core/utils/hooks/useLocalStorage';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { MLFlags } from 'flags';
import { useTracking } from '@core/services/TrackService/useTracking';

interface PaymentsSuccessButtonsProps {
  isDebitCardPayment?: boolean;
}

export const PaymentsSuccessButtons = ({
  isDebitCardPayment,
}: PaymentsSuccessButtonsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const summaryPath = useAccountSummaryPath();
  const { trackClick } = useTracking();
  const { get } = useLocalStorage();
  const autopayPromptDismissedCount = Number(
    get('dismissedAutopayPromptCount'),
  );

  const { loading, hasAutopay, canEnrollInAutopay, minimumDue } =
    usePaymentStatus();
  const {
    paymentValues: { amount },
    remainingMinDueValues: { isMinDueSchedulePrompt },
  } = useMakePayment();
  const { showEnhancedMinDuePaymentPrompt } = useFlags<MLFlags>();

  const shouldPromptForRemainingMinDue =
    showEnhancedMinDuePaymentPrompt && // FF is on for min due prompt UX
    !isMinDueSchedulePrompt && // we haven't already shown the min due prompt UI
    minimumDue !== undefined && // minimumDue is a number
    amount !== undefined && // amount is a number
    minimumDue > amount && // CH is paying less than the minimum due
    !location.pathname.includes('get-back-on-track'); // CH is NOT in re-age experience

  const onDone = () => {
    trackClick({
      name: 'Payments Success Button: Done',
      feature: 'Payments: Make Payment',
    });
    if (
      !canEnrollInAutopay || // or if they can't enroll in autopay
      hasAutopay || // or if they already have autopay set up
      autopayPromptDismissedCount >= 1 // or they've dismissed it once already
    ) {
      // skip the autopay prompt screen and navigate home
      navigate(summaryPath);
    } else {
      isDebitCardPayment ?
        navigate('../set-up-autopay')
      : navigate('../../set-up-autopay');
    }
  };

  const onContinue = () => {
    trackClick({
      name: 'Payments Success Button: Continue',
      feature: 'Payments: Make Payment',
    });
    navigate('../stay-current');
  };

  return (
    <div className="flex flex-column flex-row-ns justify-end-ns mt5">
      {shouldPromptForRemainingMinDue ?
        <Button loading={loading} onPress={onContinue} text="Continue" />
      : <Button loading={loading} onPress={onDone} text="Done" />}
    </div>
  );
};
