import { gql } from '@apollo/client';
import { Button } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ChargedOffDashboard from '../ChargedOff/Dashboards/ChargedOffDashboard';
import {
  activeSettlementOfferStatuses,
  filterOffersByStatus,
} from '../ChargedOff/utils';
import Prompts from '../Prompts';
import OnboardingDashboard from './Dashboard/OnboardingDashboard';
import { CardActivationFraudDashboard } from './Dashboard/OnboardingDashboards';
import { EarlySpendBonusBanner } from './EarlySpendBonusBanner';
import {
  getNewCardDashboardComponent,
  getReplacementCardDashboardComponent,
} from './helpers';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import GenericFallbackFull, {
  GenericFallbackFullProps,
} from '@core/components/GenericFallbacks/GenericFallbackFull';
import PageWrapper from '@core/components/Page/PageWrapper';
import {
  AccountSummaryPageQuery,
  IssuanceState,
  IssuanceType,
} from '@core/graphql/globalTypes';
import { CustomerIO, MLError, TrackService } from '@core/services';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

export const ACCOUNT_SUMMARY_PAGE_QUERY = gql`
  query AccountSummaryPage($accountId: String!) {
    customer {
      id
      contactInfo {
        email
        firstName
      }
      hasPendingAccount
    }
    creditCards {
      id
    }
    account(accountId: $accountId) {
      id
      statuses {
        hasAppFraud
        isChargedOff
        isBankrupt
      }
      cardDetails {
        last4
        issuanceType
        issuanceState
      }
    }
    offers(accountId: $accountId) {
      createdAt
      offerType
      offerId
      updatedAt
      data {
        state
        expiration
        durationMonths
        acceptanceExpiration
        canceledReason
        acceptedAt
        subType
        atpUnderSIF
        originalSettlementBalance
        originalOutstandingBalance
        remainingBalance
        fundingAccountId
        paymentPlan {
          id
          authorizationText
          source
          transactions {
            amount
            date
          }
          customerId
        }
        pastPaymentPlanIds
        payments {
          amount
          txId
          type
          date
        }
      }
    }
  }
`;

const AccountSummaryPage = () => {
  const navigate = useNavigate();
  const { isMobile } = useUserDevice();

  const {
    showNewOnboardingExperience,
    showHcrExperience,
    enableCustomerIdNotifications,
  } = useFlags();

  const { loading, data } = useAccountIdQuery<AccountSummaryPageQuery>(
    ACCOUNT_SUMMARY_PAGE_QUERY,
    { errorPolicy: 'all' },
  );

  const { customer, account, creditCards = [] } = data || {};
  const { id: customerId, contactInfo, hasPendingAccount } = customer || {};
  const { statuses, cardDetails, id: accountId } = account || {};
  const { issuanceType, issuanceState } = cardDetails || {};

  const isMulticard =
    creditCards.length ?
      creditCards.length > 1 || Boolean(hasPendingAccount)
    : false; // assume not multicard if we don't get any account info

  const showMulticardHomeLink = isMobile && isMulticard;

  useEffect(() => {
    if (customerId) {
      TrackService.identify(customerId, contactInfo?.email);

      // TODO: Remove this check once CIO moves to customerId based flow.
      // At the moment we need to check for accountID as CIO has a bug in which they take the following parameter in their api call if not id is provided
      if (accountId) {
        CustomerIO.identify({
          id: enableCustomerIdNotifications ? customerId : accountId,
          email: contactInfo?.email,
        });
      }

      TrackService.page('Account Summary', {
        customerId,
        accountId,
      });
    }
  }, [customerId]);

  // notify MLError if there's a new IssuanceType that is not handled here
  useEffect(() => {
    if (
      issuanceType &&
      ![IssuanceType.New, IssuanceType.Replacement].includes(issuanceType)
    ) {
      MLError.report({
        name: `Card issuanceType ${issuanceType} is not handled in Dashboard`,
      });
    }
  }, [issuanceType]);

  // notify MLError if there's a new IssuanceState that is not handled here
  useEffect(() => {
    if (
      issuanceState &&
      ![
        IssuanceState.PreMailed,
        IssuanceState.Mailed,
        IssuanceState.Delayed,
        IssuanceState.Returned,
        IssuanceState.Activated,
        IssuanceState.Canceled,
        IssuanceState.PreExpired,
      ].includes(issuanceState)
    ) {
      MLError.report({
        name: `Card issuanceState ${issuanceState} is not handled in Dashboard`,
      });
    }
  }, [issuanceState]);

  const customerFirstName = contactInfo?.firstName;

  let pageTitle =
    customerFirstName && !loading && !isMobile ?
      `Hi, ${customerFirstName}`
    : undefined;

  let DashboardComponent:
    | React.FunctionComponent<{ pageTitle?: string }>
    | React.FunctionComponent<GenericFallbackFullProps>;

  const hasActiveOffer =
    filterOffersByStatus(data?.offers, activeSettlementOfferStatuses).length >
    0;

  if (
    (showHcrExperience || hasActiveOffer) &&
    statuses?.isChargedOff &&
    !statuses?.isBankrupt
  ) {
    //Once settlements is released to 100% we can remove (showHcrExperience || hasActiveOffer) and just check if customer is charged off
    DashboardComponent = ChargedOffDashboard;
  } else if (statuses?.hasAppFraud) {
    DashboardComponent = CardActivationFraudDashboard;
  } else if (issuanceType === IssuanceType.New) {
    // DQ Onboarding project will be doing A/B testing, the two experiences for new accounts split here
    if (
      showNewOnboardingExperience &&
      accountId &&
      issuanceState !== IssuanceState.Activated
    ) {
      TrackService.trackEvent('Viewed New Account Onboarding Dashboard', {
        feature: 'New Account Activation A/B Testing',
        user: { ecsAccountId: accountId, ecsCustomerId: customerId },
      });

      DashboardComponent = OnboardingDashboard;
    } else {
      DashboardComponent = getNewCardDashboardComponent(issuanceState);
    }
  } else if (issuanceType === IssuanceType.Replacement) {
    DashboardComponent = getReplacementCardDashboardComponent(issuanceState);
  } else {
    pageTitle = "It's not you. It's us.";

    DashboardComponent = GenericFallbackFull;
  }

  if (loading) {
    return (
      <div
        style={{
          justifyContent: 'center',
          alignSelf: 'center',
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <PageWrapper banners={[EarlySpendBonusBanner]}>
      {showMulticardHomeLink && (
        <div className="flex justify-start pb3">
          <Button
            size="small"
            text="All cards"
            variant="text"
            icon={{
              name: 'back',
              position: 'leading',
            }}
            onPress={() => navigate('/')}
          />
        </div>
      )}
      <DashboardComponent pageTitle={pageTitle} />
      <Prompts />
    </PageWrapper>
  );
};

export default AccountSummaryPage;
