import { useFlags } from 'launchdarkly-react-client-sdk';
import { Icon } from '@missionlane/compass-ui';
import AccountBalanceContent, {
  AccountBalanceContentProps,
} from './AccountBalanceContent';
import { SecurityDepositFooter } from './SecurityDepositFooter';
import AlertMessageWithLink from './AlertMessageWithLink';
import AccountSummaryCardAction from './AccountSummaryCardAction';
import Kard, {
  KardHeader,
  KardProps,
} from '@core/components/General/Kard/Kard';
import isNil from '@core/utils/isNil';
import { RtfTreatment } from '@core/components/AccountInformation/types';
import { TrackingLinkProps } from '@core/components/General/TrackingLink';

export const ACCOUNT_SUMMARY_CARD_TESTID = 'AccountSummaryCard';

type CardAction = {
  /** content displayed as children of Link component */
  label: string | React.ReactNode;
  /** function that will be called when the link is clicked */
  linkTo: string;
  /** metadata to be used for segment tracking in the link */
  tracking: Pick<TrackingLinkProps, 'trackingName' | 'trackingProperties'>;
};

interface BaseAccountCardProps {
  availableCredit?: number | null;
  currentBalance?: number | null;
  cardName?: string | null;
  cardLast4?: string | null;
  securityDeposit?: number | null;
  disabled?: boolean;
  isPastDue?: boolean | false;
  isLocked: boolean;
  onUnlock: () => void;
  showSettledCurrentBalance?: boolean | undefined;
  /**
   * Represents that function that will be called when the card is clicked.
   * If onClick has a value the entire card is clickable and no cardAction is allowed.
   * This prop is unavailable is cardAction has a value.
   * @type () => void
   */
  onClick?: () => void;
  /**
   * Represents a function and the display label for that function.
   * It is displayed in the bottom right of the card as a Link component.
   * Only the link is clickable with this option and not the entire card.
   * This prop is unavailable if the `onClick` prop has a value.
   */
  cardAction?: CardAction;
  headerLevel?: KardHeader['level'];
  variant?: KardProps['variant'];
  rtfTreatment?: RtfTreatment;
  hasPastDueBalancePayment?: boolean;
  isChargedOff?: boolean | null;
}

/**
 * Interface that is used when the component has a value for onClick prop.
 * This prevent cardAction from also having a value.
 */
interface ClickableAccountCard extends BaseAccountCardProps {
  cardAction?: never;
  disabled?: boolean;
  onClick: () => void;
}

/**
 * Interface that is used when the component does not have an `onClick` function.
 * This prevents `disabled` from being defined as it has no affect on an unclickable card,
 * and allows a `cardAction` to be defined
 */
interface UnclickableAccountCardProps extends BaseAccountCardProps {
  onClick?: never;
  disabled?: never;
}

type AccountCardProps = ClickableAccountCard | UnclickableAccountCardProps;

export const AccountSummaryCard = ({
  availableCredit,
  cardAction,
  cardLast4,
  cardName,
  currentBalance,
  disabled,
  onClick,
  securityDeposit,
  headerLevel,
  variant = 'default',
  rtfTreatment,
  showSettledCurrentBalance,
  hasPastDueBalancePayment,
  isPastDue,
  isLocked,
  onUnlock,
  isChargedOff,
}: AccountCardProps) => {
  const { showManageCard } = useFlags();
  const canDisplayAccountBalance =
    !isNil(availableCredit) && !isNil(currentBalance);

  const availableCreditAmount =
    !!availableCredit && !isPastDue ? availableCredit : 0;
  const currentBalanceAmount =
    !!currentBalance && !showSettledCurrentBalance ? currentBalance : 0;

  const isDisabled = !canDisplayAccountBalance || disabled;

  const header: KardHeader = {
    className: 'mb2',
    level: headerLevel,
    action: showManageCard &&
      isLocked && {
        label: <Icon name="lock" color="blue" />,
        onClick: onUnlock,
      },
    textPrimary: cardName || 'Visa',
    textSecondary: cardLast4 ? `(...${cardLast4})` : undefined,
  };

  return (
    <Kard
      testID={ACCOUNT_SUMMARY_CARD_TESTID}
      header={header}
      disabled={isDisabled}
      onClick={onClick}
      variant={variant}
      footer={
        <SecurityDepositFooter
          securityDeposit={securityDeposit}
          hasLargeBottomLeftRadius={!!onClick}
        />
      }
    >
      {canDisplayAccountBalance ?
        <Content
          availableCredit={availableCreditAmount}
          currentBalance={currentBalanceAmount}
          rtfTreatment={rtfTreatment}
          hasPastDueBalancePayment={hasPastDueBalancePayment}
          isPastDue={isPastDue}
          isChargedOff={isChargedOff}
        />
      : <AlertMessageWithLink
          linkText="message us."
          message="We can't load your balance right now. Please try again later or"
          url="https://support.missionlane.com/hc/en-us/requests/new"
        />
      }
      {cardAction && <AccountSummaryCardAction cardAction={cardAction} />}
    </Kard>
  );
};

interface ContentProps extends AccountBalanceContentProps {
  isChargedOff?: boolean | null;
}

const Content = ({
  availableCredit,
  currentBalance,
  rtfTreatment,
  hasPastDueBalancePayment,
  isPastDue,
  isChargedOff,
}: ContentProps) => (
  <AccountBalanceContent
    availableCredit={availableCredit}
    currentBalance={currentBalance}
    rtfTreatment={rtfTreatment}
    hasPastDueBalancePayment={hasPastDueBalancePayment}
    isPastDue={isPastDue}
    isChargedOff={isChargedOff}
  />
);
