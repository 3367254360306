import { useEffect } from 'react';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { Formik, FormikHelpers } from 'formik';
import {
  Colors,
  P3,
  B,
  Button,
  TextField,
  Notification,
} from '@missionlane/compass-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ADD_FUNDING_ACCOUNT_MUTATION } from '../queries';
import { MANAGE_FUNDING_ACCOUNT_FEATURE_NAME } from '../ManageFundingAccounts/ManageFundingAccounts';
import BankCheck from '@payments/components/BankAccount/BankCheck/BankCheck';
import { MLError, TrackService } from '@core/services';
import {
  AddFundingAccountMutation,
  AddFundingAccountMutationVariables,
  Channel,
  FundingAccountFieldsFragment,
  FundingAccountStatus,
} from '@core/graphql/globalTypes';
import { ACH_FUNDING_ACCOUNTS_QUERY } from '@payments/graphql/fundingAccountQueries';
import Tooltip from '@core/components/General/Tooltip/Tooltip';
import Checkbox from '@core/components/General/Checkbox/Checkbox';
import PageWrapper from '@core/components/Page/PageWrapper';
import { ADD_UPDATE_AUTOPAY_QUERY } from '@payments/components/Autopay/queries';
import { useAccount } from '@core/components/Auth/AccountContext';
import PageTitle, {
  PageTitleProps,
} from '@core/components/Page/PageTitle/PageTitle';
import { useTracking } from '@core/services/TrackService/useTracking';
import BackLink from '@core/components/General/BackLink';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { opportunityTilesQuery } from '@core/components/OpportunityTileCarousel/opportunityTilesQuery';

const MAX_ROUTING_NUMBER_LENGTH = 9;
const ROUTING_NUMBER_TOOLTIP =
  'Your routing number is the first 9 digits on the bottom left of your check.';
const INVALID_ROUTING_NUMBER = 'Please enter a valid 9-digit routing number.';
const MIN_ACCOUNT_NUMBER_LENGTH = 5;
const MAX_ACCOUNT_NUMBER_LENGTH = 17;
const ACCOUNT_NUMBER_TOOLTIP =
  'Your checking account number is not the same as a debit card number. Find it on the bottom of your check or ask your bank for it.';

export const DEFAULT_FUNDING_ACCOUNT_ERROR =
  'There’s an error with your bank account number. Please double-check it and try again. If you need help, call the number on the back of your card from 9am to 8pm ET.';
export const MAX_FUNDING_ACCOUNTS_ALLOWED_ERROR =
  'It looks like this bank account is already linked to a Mission Lane account. Please add a different one. If you need help, call the number on the back of your card from 9am to 8pm ET.';
export const FUNDING_ACCOUNT_ALREADY_EXIST_ERROR =
  "You've already added this bank account. You can use this account or connect a new one.";
export const ADD_ACCOUNT_QUERY = gql`
  query AddAccount($accountId: String!) {
    account(accountId: $accountId) {
      id
      statuses {
        isChargedOff
      }
    }
  }
`;

interface FormValues {
  routingNumber: string;
  accountNumber: string;
  confirmAccountNumber: string;
  isDefault: boolean;
}

interface FormErrors {
  routingNumber?: string;
  accountNumber?: string;
  confirmAccountNumber?: string;
}

export interface AddAccountData {
  fundingAccount: FundingAccountFieldsFragment;
}

export enum BankAccountFlow {
  MAKE_PAYMENT = 'MAKE_PAYMENT',
  MODIFY_AUTOPAY = 'MODIFY_AUTOPAY',
  SETUP_AUTOPAY = 'SETUP_AUTOPAY',
  COLLECTIONS_OFFER = 'COLLECTIONS_OFFER',
  EDIT_COLLECTIONS_OFFER = 'EDIT_COLLECTIONS_OFFER',
  MANAGE_FUNDING_ACCOUNTS = 'MANAGE_FUNDING_ACCOUNTS',
  RE_AGE_PAYMENT = 'RE_AGE_PAYMENT',
}

const AddAccountLabel = ({
  label,
  tooltip,
}: {
  label: string;
  tooltip?: string;
}) => (
  <div className="flex items-center mb2">
    <P3 style={{ color: Colors.ink, margin: 0 }}>
      <B>{label}</B>
    </P3>
    {tooltip && <Tooltip message={tooltip} textStyles={{ color: 'white' }} />}
  </div>
);

const AddAccount = () => {
  const { allowActivationFundingAccountFlow } = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const account = useAccount();
  const { trackError } = useTracking();
  const [
    addFundingAccount,
    {
      data: addAccountResponse,
      loading: loadingAddAccount,
      error: addAccountError,
    },
  ] = useMutation<
    AddFundingAccountMutation,
    AddFundingAccountMutationVariables
  >(ADD_FUNDING_ACCOUNT_MUTATION, {
    refetchQueries: [
      { query: ACH_FUNDING_ACCOUNTS_QUERY },
      {
        query: ADD_UPDATE_AUTOPAY_QUERY,
        variables: { accountId: account.accountId },
      },
      {
        query: opportunityTilesQuery,
        variables: { accountId: account.accountId },
      },
    ],
  });

  const currentFlow = location.state?.currentFlow;
  const pageTitle: PageTitleProps['title'] | undefined =
    currentFlow === BankAccountFlow.EDIT_COLLECTIONS_OFFER ?
      'Edit Funding Account'
    : undefined;

  useEffect(() => {
    TrackService.page('Add Account');
  }, []);

  useEffect(() => {
    const isInManageBankAccountsFlow = Boolean(
      location.state?.currentFlow === BankAccountFlow.MANAGE_FUNDING_ACCOUNTS,
    );

    if (addAccountResponse?.addFundingAccount) {
      const { id, isDefault, numberLast4, bankName } =
        addAccountResponse.addFundingAccount;
      if (allowActivationFundingAccountFlow && isInManageBankAccountsFlow) {
        navigate('../manage-bank-accounts/success', {
          state: {
            numberLast4,
            bankName,
            addBankAccountSuccess: true,
            newDefaultFundingAccountId: isDefault ? id : undefined,
          },
        });
      } else {
        navigate(goBackPath(currentFlow), {
          state: {
            addBankAccountSuccess: true,
            newDefaultFundingAccountId: isDefault ? id : undefined,
          },
        });
      }
    }
  }, [addAccountResponse, allowActivationFundingAccountFlow, location.state]);

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    try {
      await addFundingAccount({
        variables: {
          accountNumber: values.accountNumber,
          routingNumber: values.routingNumber,
          status:
            values.isDefault ?
              FundingAccountStatus.Default
            : FundingAccountStatus.Enabled,
          channel: Channel.Web,
        },
      });

      TrackService.trackClick('Create new funding account manually', {
        feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
      });

      setSubmitting(false);
    } catch (error: unknown) {
      let errorMessage = String(error);
      // we already notify MLError of ApolloErrors in apolloClient
      if (error instanceof Error && !(error instanceof ApolloError)) {
        MLError.report({
          name: error.name,
          error,
        });
        errorMessage = error.message;
      }
      setSubmitting(false);
      trackError({
        name: 'Failed to add funding account',
        feature: 'Change Funding - Account Added via Manual',
        error: {
          code: 'PAY0006',
          message: errorMessage,
          name: 'Failed to add funding account',
        },
      });
    }
  }

  const handleErrorCode = (apolloError: ApolloError) => {
    let errorCode = 'PAY0006';
    let errorMessage = 'Failed to add funding account';
    switch (
      apolloError?.graphQLErrors[0]?.extensions?.exception?.body?.errorCode
    ) {
      case 'FUNDING_ACCOUNTS_ALREADY_EXIST':
        errorMessage = FUNDING_ACCOUNT_ALREADY_EXIST_ERROR;
        errorCode = 'PAY0007';
        break;
      case 'FUNDING_ACCOUNTS_MAX_ALLOWED_SHARED_FUNDING_ACCOUNTS':
        errorMessage = MAX_FUNDING_ACCOUNTS_ALLOWED_ERROR;
        errorCode = 'PAY0008';
        break;
      default:
        errorMessage = DEFAULT_FUNDING_ACCOUNT_ERROR;
    }
    trackError({
      name: 'Failed to add funding account',
      feature: 'Change Funding - Account Added via Manual',
      error: {
        code: errorCode,
        message: errorMessage,
        name: 'Failed to add funding account',
      },
    });
    return errorMessage;
  };

  return (
    <PageWrapper isSingleColumn>
      <BackLink className="pb3" destination={goBackPath(currentFlow)} />
      {/* We cant pass as a prop cause the back link needs to be above */}
      {pageTitle && <PageTitle title={pageTitle} />}
      <ResponsiveTypography
        className="mb4"
        type="HEADER"
        mobileLevel="H3"
        desktopLevel="H4"
      >
        Add a Checking Account
      </ResponsiveTypography>
      <Formik
        initialValues={{
          routingNumber: '',
          accountNumber: '',
          confirmAccountNumber: '',
          isDefault: true,
        }}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({
          values,
          touched,
          handleSubmit,
          setFieldValue,
          errors,
          isSubmitting,
        }) => (
          <div>
            <div>
              <BankCheck
                className="mb5"
                routing={values.routingNumber || '000000000'}
                account={values.accountNumber}
                editAccount
                editRouting={false}
              />
              <div className="mb4-ns mb3">
                <AddAccountLabel
                  label="Routing Number"
                  tooltip={ROUTING_NUMBER_TOOLTIP}
                />
                <TextField
                  accessibilityLabel="Routing Number"
                  name="routingNumber"
                  id="routingNumber"
                  placeholder="Enter your 9-digit routing number"
                  value={values.routingNumber}
                  error={
                    touched.routingNumber &&
                    errors.routingNumber && [errors.routingNumber]
                  }
                  onChangeText={(val) => {
                    handleInputChange(
                      val,
                      MAX_ROUTING_NUMBER_LENGTH,
                      (value) => {
                        setFieldValue('routingNumber', value);
                      },
                    );
                  }}
                />
              </div>
              <div className="mb4-ns mb3">
                <AddAccountLabel
                  label="Account Number"
                  tooltip={ACCOUNT_NUMBER_TOOLTIP}
                />
                <div className="fs-mask">
                  <TextField
                    accessibilityLabel="Account Number"
                    name="accountNumber"
                    id="accountNumber"
                    placeholder="Enter your account number"
                    value={values.accountNumber}
                    error={
                      touched.accountNumber &&
                      errors.accountNumber && [errors.accountNumber]
                    }
                    onChangeText={(val) => {
                      handleInputChange(
                        val,
                        MAX_ACCOUNT_NUMBER_LENGTH,
                        (value) => {
                          setFieldValue('accountNumber', value);
                        },
                      );
                    }}
                  />
                </div>
                <P3>
                  We can't accept savings accounts, prepaid cards or debit
                  cards.
                </P3>
              </div>
              <div className="mb4-ns mb3">
                <AddAccountLabel label="Confirm Account Number" />
                <div className="fs-mask">
                  <TextField
                    accessibilityLabel="Confirm Account Number"
                    name="confirmAccountNumber"
                    id="confirmAccountNumber"
                    placeholder="Enter your account number"
                    value={values.confirmAccountNumber}
                    error={
                      touched.confirmAccountNumber &&
                      errors.confirmAccountNumber && [
                        errors.confirmAccountNumber,
                      ]
                    }
                    onChangeText={(val) => {
                      handleInputChange(
                        val,
                        MAX_ACCOUNT_NUMBER_LENGTH,
                        (value) => {
                          setFieldValue('confirmAccountNumber', value);
                        },
                      );
                    }}
                  />
                </div>
              </div>
              <div className="mb5-ns mb4">
                <Checkbox
                  data-testid="checkBox"
                  disabled={loadingAddAccount}
                  onChange={() => {
                    setFieldValue('isDefault', !values.isDefault);
                  }}
                  name="isDefault"
                  id="isDefault"
                  defaultChecked
                  label="Set this bank account as my default payment account."
                />
              </div>
              {addAccountError && (
                <div className="mb5-ns mb4">
                  <Notification level="error" variant="inline">
                    {handleErrorCode(addAccountError)}
                  </Notification>
                </div>
              )}
              <div className="flex flex-column-reverse flex-row-ns justify-end-ns justify-center items-stretch items-center-ns ">
                <div className="flex-ns mr5-ns">
                  <Button
                    text="Cancel"
                    variant="text"
                    onPress={() => navigate(goBackPath(currentFlow))}
                  />
                </div>
                <Button
                  disabled={loadingAddAccount}
                  onPress={handleSubmit}
                  text="Submit"
                  loading={isSubmitting}
                />
              </div>
              <div style={{ width: '40%' }} />
            </div>
          </div>
        )}
      </Formik>
    </PageWrapper>
  );
};

export default AddAccount;

function handleInputChange(
  stringVal: string,
  maxNumLength: number,
  updateValue: (e: string) => void,
) {
  const numVal = Number(stringVal.replace('.', ''));
  if (!isNaN(numVal) && stringVal.length <= maxNumLength && numVal >= 0) {
    updateValue(stringVal);
  }
}

function validate(values: FormValues) {
  const errors: FormErrors = {};

  if (!values.routingNumber || values.routingNumber.length < 9) {
    errors.routingNumber = INVALID_ROUTING_NUMBER;
  }

  if (
    !values.accountNumber ||
    values.accountNumber.length < MIN_ACCOUNT_NUMBER_LENGTH ||
    values.accountNumber.length > MAX_ACCOUNT_NUMBER_LENGTH
  ) {
    errors.accountNumber = 'Please enter a valid checking account number.';
  }

  if (values.accountNumber !== values.confirmAccountNumber) {
    errors.confirmAccountNumber = "The account numbers don't match.";
  }

  return errors;
}

const goBackPath = (bankAccountFlow: BankAccountFlow) => {
  switch (bankAccountFlow) {
    case BankAccountFlow.MAKE_PAYMENT:
      return '../payments/pay/make-ach-payment';
    case BankAccountFlow.MODIFY_AUTOPAY:
    case BankAccountFlow.SETUP_AUTOPAY:
      return '../payments/autopay';
    case BankAccountFlow.COLLECTIONS_OFFER:
      return '../payment-plan/create';
    case BankAccountFlow.EDIT_COLLECTIONS_OFFER:
      return '../edit-offer-funding-account';
    case BankAccountFlow.MANAGE_FUNDING_ACCOUNTS:
      return '../manage-bank-accounts';
    case BankAccountFlow.RE_AGE_PAYMENT:
      return '../payments/pay/get-back-on-track';
    default:
      return '../../';
  }
};
