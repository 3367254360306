import cx from 'classnames';
import { useEffect } from 'react';
import { useOfferQuestions } from '../utils/useOfferQuestions';
import NavLinks from '../NavLinks';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';
import OfferQuestion from './OfferQuestion';
import { PaymentPlanStepProps } from './CreatePaymentPlanFlow';
import { AnswerInputType } from './OfferAnswerInput';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { CreateOfferFlowQuestionType } from '@core/graphql/globalTypes';
import { TrackService } from '@core/services';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

const OFFER_QUESTIONS = {
  MATP: 'how much would you be able to pay each month?',
  TATP: 'how much do you think you could pay off in',
};

// Prompts the customer with a set of questions, one a time, with the goal of generating an offer
// Questions and the expected answer types are generated by the offer service so we don't know them in advance
const OfferQuestions = ({ nextStep, flowId }: PaymentPlanStepProps) => {
  const { isMobile } = useUserDevice();
  const {
    currentQuestion,
    handleBacktrack,
    canBacktrack,
    loading,
    submitAnswer,
    error,
  } = useOfferQuestions({
    flowId,
    onOfferGenerated: nextStep,
  });

  useEffect(() => {
    if (loading || error || !currentQuestion?.text) {
      return;
    }

    const text = currentQuestion.text.toLowerCase();

    if (text.includes(OFFER_QUESTIONS.MATP)) {
      TrackService.trackPage(`Create Program - MATP Question`, {
        feature: SETTLEMENTS_FEATURE_NAME,
      });
    }

    if (text.includes(OFFER_QUESTIONS.TATP)) {
      TrackService.trackPage(`Create Program - TATP Question`, {
        feature: SETTLEMENTS_FEATURE_NAME,
      });
    }
  }, [currentQuestion?.text, loading, error]);

  // This scenario should never happen, but we need to cover it to make TS happy
  // If we do ever need to support this input type here, we will need to build a list
  // of options depending on the question, because Servicing Offers does not give them to us
  if (currentQuestion?.inputType === CreateOfferFlowQuestionType.Select) {
    throw new Error(
      'OfferQuestions is not currently set up to handle SELECT inputType',
    );
  }

  if (error) {
    throw error;
  }

  if (loading || !currentQuestion) {
    return <LoadingSpinner />;
  }

  return (
    <div className={cx('flex flex-column', !isMobile && 'w6')}>
      <NavLinks onGoBack={canBacktrack ? handleBacktrack : undefined} />
      <div className="mt3 payment-plan-kard">
        <OfferQuestion
          questionText={currentQuestion.text}
          inputType={currentQuestion.inputType || AnswerInputType.Text}
          onAnswerSubmitted={(answer: string) => submitAnswer(answer)}
        />
      </div>
    </div>
  );
};

export default OfferQuestions;
