import { useState } from 'react';

import { Colors, H5, Icon, P3 } from '@missionlane/compass-ui';

import './GoodStandingTips.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faEnvelopeOpenDollar,
  faLockOpen,
} from '@fortawesome/pro-regular-svg-icons';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import * as COPY from './copy';
import Kard from '@core/components/General/Kard/Kard';
import { useSpendBonus } from '@clip/SpendBonus/hooks';
import useClipOffer from '@clip/ClipProgress/hooks/useClipOffer';
import { ClipOfferStatusCodes } from '@clip/ClipProgress/hooks/types/ClipOffer';

export const GOOD_STANDING_TIPS_KARD_TEST_ID =
  'GOOD_STANDING_TIPS_KARD_TEST_ID';

export const getGoodStandingTipButtonTestId = (id: string) =>
  `good-standing-tip-button-test-id-${id}`;

const isFontAwesomeIcon = (
  iconName: string,
): iconName is 'envelope-open-dollar' | 'lock-open' => {
  return iconName === 'envelope-open-dollar' || iconName === 'lock-open';
};

const faIcons = {
  'envelope-open-dollar': faEnvelopeOpenDollar,
  'lock-open': faLockOpen,
} as const;

interface GoodStandingTipsProps {
  alwaysDisplay?: boolean;
}

export const GoodStandingTips = ({
  alwaysDisplay = false,
}: GoodStandingTipsProps) => {
  const [expandedIndex, setIsExpandedIndex] = useState<number | null>(null);
  const { clipStatus } = useClipOffer();
  const { isEnabled } = useSpendBonus();

  const shouldShowTips =
    isEnabled || clipStatus === ClipOfferStatusCodes.INELIGIBLE;

  if (!shouldShowTips && !alwaysDisplay) return null;

  const toggleExpanded = (index: number) => () => {
    if (index === expandedIndex) {
      return setIsExpandedIndex(null);
    }
    return setIsExpandedIndex(index);
  };

  return (
    <Kard className="mb3 w-100" testID={GOOD_STANDING_TIPS_KARD_TEST_ID}>
      <H5
        style={{
          fontSize: 20,
          fontWeight: '600',
          lineHeight: 32,
        }}
      >
        {COPY.header}
      </H5>
      <div className="good-standing-tips-container">
        {COPY.tips.map((tip, i) => (
          <button
            data-testid={getGoodStandingTipButtonTestId(tip.testID)}
            onClick={toggleExpanded(i)}
            type="button"
            className="good-standing-tips-button"
            key={`accordian-item-${i}`}
          >
            <div className="good-standing-accordian-label">
              <div className="good-standing-label-container">
                {/* compass icon does not render testID prop even though it takes it. This is reflected in the tests. */}
                {isFontAwesomeIcon(tip.iconName) ?
                  <FontAwesomeIcon icon={faIcons[tip.iconName]} size="sm" />
                : <Icon name={tip.iconName} color="ink" />}
                <P3
                  style={{
                    fontWeight: '600',
                    color: Colors.ink,
                    margin: 0,
                  }}
                >
                  {tip.label}
                </P3>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={expandedIndex === i ? faMinus : faPlus}
                  size="sm"
                  color={Colors.blue}
                />
              </div>
            </div>

            {expandedIndex === i && (
              <div className="good-standing-tips-expanded-container">
                <P3>{tip.content}</P3>
              </div>
            )}
          </button>
        ))}
      </div>
    </Kard>
  );
};
