import cx from 'classnames';
import { B, Button } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';
import BalanceKard from '../Kards/BalanceKard';
import ChargedOffDashboardFooter from './ChargedOffDashboardFooter';
import Kard, { KardHeader } from '@core/components/General/Kard/Kard';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { TrackService } from '@core/services';
import { DashboardPropsBase } from '@core/components/AccountSummaryPage/types';
import PageTitle from '@core/components/Page/PageTitle/PageTitle';

const FulfilledSIFDashboard = ({ pageTitle }: DashboardPropsBase) => {
  const navigate = useNavigate();
  const { isMobile, isDesktopLarge } = useUserDevice();
  const { isDebitEligible } = usePaymentStatus();

  useEffect(() => {
    TrackService.trackPage('Charged Off Dashboard - SIF Offer Complete', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
  }, []);

  const makeAnotherPaymentKardHeader: KardHeader = {
    level: isMobile ? 'H2' : 'H3',
    textPrimary: 'You’ve paid off your',
    textSecondary: 'settlement balance!',
    className: 'mb3',
  };
  return (
    <>
      {pageTitle && <PageTitle title={pageTitle} />}
      <div
        className={cx(
          'flex',
          !isDesktopLarge && 'flex-column flex-column-reverse',
        )}
      >
        <div className={cx(isDesktopLarge ? 'w-50 mr4' : 'mt2')}>
          <BalanceKard />
        </div>
        <div className={cx(isDesktopLarge && 'w-50')}>
          <Kard header={makeAnotherPaymentKardHeader}>
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P2"
              className="mb3"
            >
              Way to go! You’ve paid off your full settlement balance.
            </ResponsiveTypography>
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P2"
              className="mb5"
            >
              <B color="ink">You aren’t required to repay</B> the forgiven
              balance, but you can still{' '}
              <B color="ink">reach paid in full status</B> if you do.
            </ResponsiveTypography>
            <div className={cx(isDesktopLarge && 'flex')}>
              <Button
                onPress={() => {
                  isDebitEligible ?
                    navigate('../payments/pay')
                  : navigate('../payments/pay/make-ach-payment');
                }}
                text="Pay Forgiven Balance"
              />
            </div>
          </Kard>
        </div>
      </div>
      <ChargedOffDashboardFooter />
    </>
  );
};

export default FulfilledSIFDashboard;
