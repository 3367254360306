import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { P3 } from '@missionlane/compass-ui';
import { useReactiveVar } from '@apollo/client';
import { activeAccountIdVar } from '../Navigation/NavContainer';
import CustomerAddress from '@core/components/General/CustomerAddress';
import { CONTACT_US_LINK } from '@core/utils/constants';

import truckSrc from '@core/assets/truck.png';
import SupportPhoneLink from '@core/components/General/SupportPhoneLink';

interface TextBlockProps {
  children: ReactNode;
}
const TextBlock = ({ children }: TextBlockProps) => (
  <div className="pb3 pb4-ns">
    <P3>{children}</P3>
  </div>
);

const Truck = () => (
  <div className="tc pv4">
    <img src={truckSrc} alt="truck illustration" />
  </div>
);

interface CommonLinkProps {
  children: ReactNode;
}

const MessageLink = ({ children }: CommonLinkProps) => (
  <a
    className="link blue hover-indigo underline"
    href={CONTACT_US_LINK}
    target="_blank"
    rel="noreferrer"
  >
    {children}
  </a>
);

const ChangePINLink = ({ children }: CommonLinkProps) => {
  const activeAccountId = useReactiveVar(activeAccountIdVar);

  return (
    <Link
      to={`/account/${activeAccountId}/manage-card/modify-pin`}
      className="link blue hover-indigo underline"
    >
      {children}
    </Link>
  );
};

interface UnderlinedPhoneLinkProps {
  isChargedOff: boolean;
}

const UnderlinedPhoneLink = ({ isChargedOff }: UnderlinedPhoneLinkProps) => (
  <SupportPhoneLink className="underline" shouldCallRecoveries={isChargedOff} />
);

const ReplacementMailedContent = () => {
  return (
    <>
      <Truck />
      <TextBlock>
        We&rsquo;re mailing your credit card with activation instructions. It
        should arrive in 7-14 calendar days.
      </TextBlock>
      <TextBlock>
        If your card was lost or stolen, your PIN for cash advances will remain
        the same. You can always <ChangePINLink>change your PIN</ChangePINLink>{' '}
        if you'd like a new one.
      </TextBlock>
      <TextBlock>We&rsquo;re mailing your card to:</TextBlock>
      <CustomerAddress textColor="ink" className="pl4-ns" />
    </>
  );
};

const ReplacementDelayedContent = ({
  isChargedOff,
}: UnderlinedPhoneLinkProps) => {
  return (
    <>
      <Truck />
      <TextBlock>
        It&rsquo;s been 30 days since we issued your new credit card so it
        should&rsquo;ve arrived by now.
      </TextBlock>
      <TextBlock>We mailed it to:</TextBlock>
      <CustomerAddress textColor="ink" className="pb3 pb4-ns pl4-ns" />
      <TextBlock>
        If you haven&rsquo;t received it,{' '}
        <MessageLink>send us a message</MessageLink> or call our customer
        support team at <UnderlinedPhoneLink isChargedOff={isChargedOff} />.
      </TextBlock>
    </>
  );
};

const ReplacementReturnedContent = ({
  isChargedOff,
}: UnderlinedPhoneLinkProps) => {
  return (
    <>
      <TextBlock>
        Your card was sent back. Please{' '}
        <MessageLink>send us a message</MessageLink> or call our customer
        support team at <UnderlinedPhoneLink isChargedOff={isChargedOff} />.
      </TextBlock>
      <TextBlock>We mailed it to:</TextBlock>
      <CustomerAddress textColor="ink" className="pl4-ns" />
    </>
  );
};

export {
  ReplacementMailedContent,
  ReplacementDelayedContent,
  ReplacementReturnedContent,
};
