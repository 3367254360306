import { useFlags } from 'launchdarkly-react-client-sdk';
import { ClipOfferExperience } from '@clip/ClipProgress/hooks/types/ClipOffer';
import ClipOfferGuidance from '@clip/ClipProgress/components/ClipOffer/Guidance/ClipOfferGuidance';
import Streak from '@clip/ClipProgress/components/ClipOffer/Streak/Streak';
import useClipOfferV2 from '@clip/ClipProgress/hooks/useClipOfferV2';
import isNil from '@core/utils/isNil';

const ClipOffer = () => {
  const {
    clipTrackerRelaunchControl: streakFeatFlag,
    clipTrackerRelaunch: trackerFeatFlag,
  } = useFlags();
  const clipOffer = useClipOfferV2();

  if (
    trackerFeatFlag &&
    clipOffer?.clipStatus &&
    clipOffer?.experience === ClipOfferExperience.TRACKER
  ) {
    return <ClipOfferGuidance />;
  }

  if (
    streakFeatFlag &&
    !isNil(clipOffer?.currentPaymentStreak) &&
    clipOffer?.experience === ClipOfferExperience.STREAK
  ) {
    return <Streak />;
  }

  return null;
};

export default ClipOffer;
