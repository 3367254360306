import cx from 'classnames';
import { Icon, Spacing } from '@missionlane/compass-ui';
import { ReactNode } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ClickableKardVariants } from '../General/Kard/Kard';
import AlertMessageWithLink from '../AccountSummaryCard/AlertMessageWithLink';
import { SecurityDepositFooter } from '../AccountSummaryCard/SecurityDepositFooter';
import KardBackground from './KardBackground';
import DynamicCardArtHeader from './DynamicCardArtHeader';
import AccountSummaryContent from './AccountSummaryContent';
import isNil from '@core/utils/isNil';
import { BASE_Z_INDEX } from '@core/utils/zIndexes';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

const originalWidth = 496;
const originalHeight = 312;
const aspectRatio = originalWidth / originalHeight;

export interface DynamicAccountSummaryKardProps {
  cardArtUrl: string;
  availableCredit?: number | null;
  currentBalance?: number | null;
  cardName?: string | null;
  last4?: string | null;
  footer?: ReactNode;
  variant?: ClickableKardVariants;
  disabled?: boolean;
  isLocked: boolean;
  onUnlock: () => void;
  onClick?: () => void;
  isPastDue?: boolean;
  showSettledCurrentBalance?: boolean | undefined;
  securityDeposit?: number | null;
  isHomeScreen?: boolean;
}

const DynamicAccountSummaryKard = ({
  cardArtUrl,
  last4,
  cardName,
  availableCredit,
  securityDeposit,
  variant,
  disabled,
  isLocked,
  onUnlock,
  currentBalance,
  isPastDue,
  showSettledCurrentBalance,
  onClick,
  isHomeScreen,
}: DynamicAccountSummaryKardProps) => {
  const { isMobile } = useUserDevice();
  const { showManageCard } = useFlags();
  const canDisplayAccountBalance =
    !isNil(availableCredit) && !isNil(currentBalance);

  const availableCreditAmount =
    !!availableCredit && !isPastDue ? availableCredit : 0;
  const currentBalanceAmount =
    !!currentBalance && !showSettledCurrentBalance ? currentBalance : 0;

  const isDisabled = !canDisplayAccountBalance || disabled;
  const isLightTheme = cardArtUrl?.includes('green');

  return (
    <div
      style={{
        position: 'relative',
        width: isHomeScreen && !isMobile ? '45%' : '100%',
        aspectRatio,
        marginBottom: Spacing.m,
      }}
      onClick={onClick}
      className={cx('br4 shadow-2 flex', onClick && !disabled && 'pointer')}
    >
      <div style={{ zIndex: BASE_Z_INDEX, width: '100%', padding: Spacing.xm }}>
        <DynamicCardArtHeader
          showManageCard={showManageCard}
          isLightTheme={isLightTheme}
          last4={last4}
          cardName={cardName}
          isLocked={isLocked}
          onUnlock={onUnlock}
        />
        {canDisplayAccountBalance ?
          <AccountSummaryContent
            availableCreditAmount={availableCreditAmount}
            currentBalanceAmount={currentBalanceAmount}
            isLightTheme={isLightTheme}
          />
        : <AlertMessageWithLink
            linkText="message us."
            message="We can't load your balance right now. Please try again later or"
            url="https://support.missionlane.com/hc/en-us/requests/new"
          />
        }
      </div>
      <SecurityDepositFooter
        securityDeposit={securityDeposit}
        hasLargeBottomLeftRadius={!!onClick}
      />
      {variant === 'navigationArrow' && (
        <div
          className={`w2 z-1 br-right-4 flex items-center justify-center bg-pine ${
            isDisabled ? 'o-50' : ''
          }`}
        >
          <Icon name="forward" color="white" />
        </div>
      )}
      <KardBackground cardArtUrl={cardArtUrl} />
    </div>
  );
};

export default DynamicAccountSummaryKard;
