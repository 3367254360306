import { gql } from '@apollo/client';

export const PROMPTS_QUERY = gql`
  query PromptsManager($accountId: String!, $dismissedAt: PromptsDismissedAt) {
    promptsManager(accountId: $accountId, dismissedAt: $dismissedAt) {
      prompts {
        id
      }
      variant
    }
  }
`;
