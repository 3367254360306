import dayjs from 'dayjs';
import { FC } from 'react';

import CreditProtectionModal, {
  CREDIT_PROTECTION_MODAL_DISMISSED_AT,
} from '../CreditProtection/CreditProtectionModal';
import { PromptId, PromptsDismissedAt } from '@core/graphql/globalTypes';

export interface PromptProps {
  isOpen: boolean;
  onDismiss: (promptId: PromptId) => void;
}

export const promptsMap: Partial<Record<PromptId, FC<PromptProps>>> = {
  [PromptId.CreditProtection]: CreditProtectionModal,
} as const;

export const promptStorageKeys: Partial<Record<PromptId, string>> = {
  [PromptId.CreditProtection]: CREDIT_PROTECTION_MODAL_DISMISSED_AT,
} as const;

const getDismissState = (promptId: PromptId) => {
  const dismissedAtKey = promptStorageKeys[promptId];

  if (dismissedAtKey) {
    const dismissedAt = localStorage.getItem(dismissedAtKey);

    if (dismissedAt) return dismissedAt;
  }

  return null;
};

export const setPromptDismissed = (promptId: PromptId) => {
  const dismissedAtKey = promptStorageKeys[promptId];

  if (dismissedAtKey) {
    localStorage.setItem(dismissedAtKey, dayjs().toISOString());
  }

  return;
};

export const getPromptsDismissedAt = (): PromptsDismissedAt => ({
  [PromptId.Biometrics]: 'NOT_APPLICABLE',
  [PromptId.CreditProtection]: getDismissState(PromptId.CreditProtection),
  [PromptId.IncomeUpdate]: 'NOT_APPLICABLE',
});
