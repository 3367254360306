import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  B,
  BubbleIcon,
  LoadingIndicator,
  Notification,
  P3,
  TextField,
  List,
  ListItem,
} from '@missionlane/compass-ui';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MakePaymentPaymentType, UIPaymentType } from './types';
import { BALANCE_INFO_QUERY } from './gql/queries';
import isPastDate from '@payments/utils/isPastDate';
import { MakePayment, PaymentType } from '@core/graphql/globalTypes';
import { useAccount } from '@core/components/Auth/AccountContext';
import { centsToDollars } from '@core/utils/centsToDollars';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { MAX_ALLOWED_PAYMENT } from '@payments/utils/contants';
import { getUTC, getET } from '@core/utils/timezones';

type OneTimePaymentType = Extract<
  PaymentType,
  | PaymentType.OneTimeCurrentBalance
  | PaymentType.OneTimeFixed
  | PaymentType.OneTimeMinimum
  | PaymentType.OneTimeStatementBalance
>;

interface Props {
  paymentInfo: MakePayment.PaymentInfo;
  selected: MakePaymentPaymentType;
  amount: number;
  onChange: (id: PaymentType, amount: number | null) => void;
  className?: string;
  maxPaymentAmount?: number;
  isImmediate: boolean;
  isMinDueSchedulePrompt?: boolean;
  optimisticMinDueAmount?: number;
  reAgeAmount?: number;
}

const PaymentAmount = ({
  paymentInfo,
  selected,
  amount: currentAmount,
  onChange,
  className,
  maxPaymentAmount = MAX_ALLOWED_PAYMENT,
  isImmediate,
  isMinDueSchedulePrompt,
  optimisticMinDueAmount,
  reAgeAmount,
}: Props) => {
  const { accountId } = useAccount();
  const { isPastDue, isLate, pastDueBalance, isChargedOff } =
    usePaymentStatus();
  const { showHcrExperience, contextualPaymentSupport } = useFlags();

  const { data, loading } = useQuery(BALANCE_INFO_QUERY, {
    variables: { accountId },
  });
  const displayContextualPaymentSupport =
    contextualPaymentSupport && !isPastDue;

  const chargedOffExperience = isChargedOff && showHcrExperience;

  const initialCustomAmount =
    selected === PaymentType.OneTimeFixed ?
      (currentAmount / 100).toFixed(2)
    : '';

  const [errors, setErrors] = useState<string[]>([]);
  const [helperText, setHelperText] = useState<string | undefined>();
  const [customAmount, setCustomAmount] = useState<string>(initialCustomAmount);
  const { minimumDue, printDueDate, nextStatementCloseDate } = paymentInfo;
  const { remainingStatementBalance, currentBalance, statementBalance } =
    data?.account?.balanceInfo || {};

  const showWarning =
    remainingStatementBalance === undefined ||
    currentBalance === undefined ||
    statementBalance === undefined;

  const paymentOptionValues = {
    [PaymentType.OneTimeMinimum]: minimumDue,
    [PaymentType.OneTimeStatementBalance]: remainingStatementBalance,
    [PaymentType.OneTimeCurrentBalance]: currentBalance,
    [UIPaymentType.PastDueBalance]: pastDueBalance,
  };

  useEffect(() => {
    validateAmount(currentAmount);
  }, [isImmediate]);

  useEffect(() => {
    if (reAgeAmount !== undefined) {
      //if in reAge experience have the Re Age Offer be pre-selected
      handleChange(PaymentType.OneTimeFixed);
    } else if (isMinDueSchedulePrompt && optimisticMinDueAmount !== undefined) {
      handleChange(PaymentType.OneTimeMinimum);
    }
  }, [optimisticMinDueAmount, isMinDueSchedulePrompt, reAgeAmount]);

  function handleChange(id: OneTimePaymentType) {
    if (id === selected) {
      return;
    }

    // reset the custom amount when selecting a different payment option
    setCustomAmount('');

    let amount = id === PaymentType.OneTimeFixed ? 0 : paymentOptionValues[id];
    if (
      isMinDueSchedulePrompt &&
      id === PaymentType.OneTimeMinimum &&
      optimisticMinDueAmount !== undefined
    )
      amount = optimisticMinDueAmount;
    if (!validateAmount(amount)) {
      amount = id === PaymentType.OneTimeCurrentBalance ? amount : null;
    }
    if (reAgeAmount !== undefined && id === PaymentType.OneTimeFixed) {
      //if in reAge experience, Custom Amount tile is replaced with static Re Age offer
      amount = reAgeAmount;
      setHelperText(
        'Please note, the required amount to claim your offer may differ from your offer email to account for recent activity.',
      );
    }
    onChange(id, amount);
  }

  const handleCustomAmountChange = (e: string) => {
    const value = Number(e.replace('.', ''));
    if (!isNaN(value) && value < 99999999 && value >= 0) {
      validateAmount(value);
      setCustomAmount((value / 100).toFixed(2).toString());
      onChange(PaymentType.OneTimeFixed, value);
    }
  };

  function validateAmount(amount: number) {
    let valid = true;
    const newErrors = [];
    let newHelperText = '';
    if (
      !chargedOffExperience &&
      amount &&
      amount < minimumDue &&
      !isMinDueSchedulePrompt
    ) {
      newHelperText =
        isPastDue ?
          `To avoid a late fee, pay the remaining minimum due of ${centsToDollars(
            minimumDue - amount,
          )} by ${dayjs(printDueDate).format('MMM D')}`
        : 'Note that this amount is not enough to cover your minimum payment due.';
    }
    if (amount > maxPaymentAmount && isImmediate) {
      newErrors.push(
        `Your payment amount can't exceed ${centsToDollars(
          maxPaymentAmount,
        )}, which is your current balance of ${centsToDollars(
          currentBalance,
        )} minus other scheduled or pending payments.`,
      );
      valid = false;
    }

    setErrors(newErrors);
    setHelperText(newHelperText);
    return valid;
  }

  const getDescription = () => {
    if (isLate) {
      return `Minimum payment due now`;
    }

    if (displayContextualPaymentSupport) {
      return `Pay by ${dayjs(printDueDate).format('M/D')} to avoid a late fee.`;
    }

    return `Minimum payment ${printDueDate ? dueDateString : ''}`;
  };

  const getIconColor = () => {
    return isLate ? 'mustard' : 'green';
  };

  const getStatementBalanceDescription = () => {
    if (displayContextualPaymentSupport) {
      return `Less any payments already made. This balance may not reflect all payments returned.\nPay by ${dayjs(printDueDate).format('MM/D')} to reduce interest charges if applicable.`;
    } else {
      return 'Less any payments already made. This balance may not reflect all payments returned.';
    }
  };

  const currentBalanceInDollars = centsToDollars(currentBalance);
  const minDueInDollars = centsToDollars(minimumDue);
  /*Remaining statement balance had an incident that caused remaining statement balance to be larger than current balance.
   This has been fixed in the backend by correctly accounting for returned payments, but we are keeping this logic as a saftey check.
   More context _inc-1565-minimal-cust_dig_exp-ide*/
  const remainingStatementBalanceInDollars =
    remainingStatementBalance >= currentBalance ?
      centsToDollars(currentBalance)
    : centsToDollars(remainingStatementBalance);

  const flexLoading = (
    <div className="flex items-center">
      <div className="pr3">
        <BubbleIcon
          name="dollarSign"
          iconColor="green"
          bubbleColor="greenWashed"
        />
      </div>
      <div>
        <LoadingIndicator />
      </div>
    </div>
  );

  const dueDateString = `\ndue ${getUTC(printDueDate).format(
    'MM/DD/YY',
  )} by 5:59 p.m. ET`;

  const oneTimeStatementBalance =
    loading ?
      <ListItem id="oneTimeStatementBalanceLoading">{flexLoading}</ListItem>
    : <ListItem
        id={PaymentType.OneTimeStatementBalance}
        disabled={
          loading ||
          remainingStatementBalance === undefined ||
          isPastDate(getET(), nextStatementCloseDate) ||
          remainingStatementBalance <= 0
        }
        label={`${remainingStatementBalanceInDollars} Statement Balance`}
        description={getStatementBalanceDescription()}
        iconProps={{ icon: 'dollarSign', iconColor: 'green' }}
      />;

  const oneTimeCurrentBalance =
    loading ?
      <ListItem id="oneTimeCurrentBalanceLoading">{flexLoading}</ListItem>
    : <ListItem
        id={PaymentType.OneTimeCurrentBalance}
        disabled={loading || currentBalance === undefined}
        label={`${currentBalanceInDollars} Current Balance`}
        iconProps={{ icon: 'dollarSign', iconColor: 'green' }}
      />;

  return (
    <div className={className}>
      {!loading && showWarning && (
        <Notification level="warning">
          Some options are currently unavailable. We apologize for the
          inconvenience.
        </Notification>
      )}
      <List variant="selectable" onChange={handleChange} selected={selected}>
        {!chargedOffExperience && isPastDue && !isMinDueSchedulePrompt && (
          <ListItem
            id={PaymentType.OneTimeMinimum}
            disabled={
              isPastDate(getET(), nextStatementCloseDate) || minimumDue <= 0
            }
            label={`${centsToDollars(minimumDue)} Minimum Due`}
            description={` Due by ${dayjs(printDueDate).format(
              'M/D',
            )} to avoid a late fee.`}
            iconProps={{
              icon: 'dollarSign',
              iconColor: 'red',
            }}
          />
        )}
        {isMinDueSchedulePrompt && optimisticMinDueAmount !== undefined && (
          <ListItem
            id={PaymentType.OneTimeMinimum}
            disabled={
              isPastDate(getET(), nextStatementCloseDate) || minimumDue <= 0
            }
            label={centsToDollars(optimisticMinDueAmount)}
            description={`Minimum Due by ${dayjs(printDueDate).format(
              'MMM D',
            )} to avoid a late fee.`}
            iconProps={{
              icon: 'dollarSign',
              iconColor: 'green',
            }}
          />
        )}
        {!chargedOffExperience && isPastDue && !isMinDueSchedulePrompt && (
          <ListItem
            id={UIPaymentType.PastDueBalance}
            label={`${centsToDollars(pastDueBalance || 0)} Past Due`}
            description="Required to use your card again."
            iconProps={{
              icon: 'dollarSign',
              iconColor: 'red',
            }}
          />
        )}
        {!chargedOffExperience && !isPastDue && (
          <ListItem
            id={PaymentType.OneTimeMinimum}
            disabled={
              isPastDate(getET(), nextStatementCloseDate) || minimumDue <= 0
            }
            label={
              displayContextualPaymentSupport ?
                `${minDueInDollars} Minimum Due`
              : minDueInDollars
            }
            description={getDescription()}
            iconProps={{
              icon: 'dollarSign',
              iconColor: getIconColor(),
            }}
          />
        )}
        {!chargedOffExperience && oneTimeStatementBalance}
        {oneTimeCurrentBalance}
        {reAgeAmount === undefined ?
          <ListItem id={PaymentType.OneTimeFixed}>
            <div className="flex items-center">
              <div className="pr3">
                <BubbleIcon
                  name="dollarSign"
                  iconColor="green"
                  bubbleColor="greenWashed"
                />
              </div>
              <div>
                <div>
                  <P3 color="ink" style={{ marginVertical: 0 }}>
                    <B>
                      {displayContextualPaymentSupport ?
                        'Other amount'
                      : 'Other'}
                    </B>
                  </P3>
                </div>
                <div>
                  <TextField
                    accessibilityLabel="Custom Amount"
                    icon="dollarSign"
                    name="textField"
                    value={customAmount}
                    placeholder="0.00"
                    onChangeText={handleCustomAmountChange}
                  />
                </div>
              </div>
            </div>
          </ListItem>
        : <ListItem
            id={PaymentType.OneTimeFixed}
            disabled={reAgeAmount === undefined}
            label={`${centsToDollars(reAgeAmount)} special offer`}
            description="Bring your account current"
            iconProps={{
              icon: 'dollarSign',
              iconColor: 'green',
            }}
          />
        }
      </List>
      {helperText && (
        <div className="mt3">
          <Notification variant="inline" level="info">
            {helperText}
          </Notification>
        </div>
      )}
      {errors.map((error, key) => (
        <div className="mw6" key={key}>
          <Notification variant="inline" level="error">
            {error}
          </Notification>
        </div>
      ))}
    </div>
  );
};

export default PaymentAmount;
