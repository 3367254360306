import { H5, P3, Spacing } from '@missionlane/compass-ui';
import { useEffect } from 'react';
import { REWARDS_REDEMPTION_FEATURE_NAME } from './const';
import { useTracking } from '@core/services/TrackService/useTracking';

export const NoRedeemableRewardsMessage = () => {
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent({
      eventName: 'Ineligible to Redeem Rewards Message Viewed',
      feature: REWARDS_REDEMPTION_FEATURE_NAME,
    });
  }, []);

  return (
    <div>
      <H5 style={{ marginBottom: Spacing.s }}>You can’t redeem right now</H5>
      <P3>
        Looks like you fell behind on payments or have an account issue. Resolve
        either to redeem your Cash Back.
      </P3>
    </div>
  );
};
