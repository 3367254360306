import { useEffect, useState } from 'react';
import { AuthnTransaction } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import LockedOut from '../LockedOut';
import LoginPage from './LoginPage';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useTracking } from '@core/services/TrackService/useTracking';
import { PageHeader } from '@core/components/Page/PageHeader';

const HOME_PAGE = '/';

const LoginFlow = () => {
  const { trackEvent } = useTracking();
  const { oktaAuth } = useOktaAuth();
  const [transaction, setTransaction] = useState<AuthnTransaction>();
  const navigate = useNavigate();

  useEffect(() => {
    const redirectIfLoggedIn = async () => {
      if (await oktaAuth.isAuthenticated()) {
        navigate(HOME_PAGE);
        trackEvent({
          feature: 'Login',
          eventName: 'User Redirected to Home Page',
        });
      }
    };

    redirectIfLoggedIn();
  }, [oktaAuth]);

  useEffect(() => {
    async function onSuccess() {
      let originalUri = oktaAuth.getOriginalUri() || HOME_PAGE;

      if (window.location.search) {
        // pass through any search params from the original location to
        // the okta orginalUri param so we can be correctly redirected
        originalUri += window.location.search;
      }

      if (oktaAuth.isLoginRedirect()) {
        // If the user was redirected to login after authentication expired
        // handleLoginRedirect will handle the tokens and then redirect
        // the user to the URL they came from
        await oktaAuth.handleLoginRedirect();
      } else {
        // Complete the Okta flow by redirecting to Okta and getting the tokens
        // This is where authState.isAuthenticated is updated
        // Redirects to /signin/callback
        await oktaAuth.signInWithRedirect({
          // @ts-ignore incorrect typings? https://developer.okta.com/code/react/okta_react/#create-a-custom-sign-in-form
          sessionToken: transaction.sessionToken,
          originalUri,
        });
      }
    }
    if (transaction?.status === 'SUCCESS') {
      onSuccess();
    }
  }, [transaction]);

  const renderStep = () => {
    switch (transaction?.status) {
      case 'SUCCESS':
        return <LoadingSpinner />;
      case 'LOCKED_OUT':
        return <LockedOut feature="Login" />;
      default:
        return <LoginPage onSuccess={setTransaction} />;
    }
  };

  return <div>{renderStep()}</div>;
};

const LoginFlowWithPageWrapper = () => (
  <>
    <PageWrapper centerContent withFooter>
      <div className="pb4">
        <PageHeader centerContent hasVerticalPaddingOnly />
      </div>
      <LoginFlow />
    </PageWrapper>
  </>
);

export default LoginFlowWithPageWrapper;
