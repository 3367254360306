import { P3 } from '@missionlane/compass-ui';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PromptProps } from '../Prompts/helpers';
import creditProtectionCardAndShield from '@core/assets/CreditProtectionCardAndShield.svg';
import Modal from '@core/components/General/Modal/Modal';
import { PromptId } from '@core/graphql/globalTypes';
import { useTracking } from '@core/services/TrackService/useTracking';

export const CREDIT_PROTECTION_MODAL_EVENT = 'credit-protection-modal';

/*
Business occasionally requests a reset of the modal to show it again to all users.
Incrementing the version will reset the modal for all users.
*/
export const CREDIT_PROTECTION_MODAL_DISMISSED_AT =
  'credit-protection-modal-dismissed-at-v1';

const CreditProtectionModal: FC<PromptProps> = ({ isOpen, onDismiss }) => {
  const { trackClick, trackEvent } = useTracking();
  const navigate = useNavigate();

  const onClose = () => {
    trackClick({
      feature: CREDIT_PROTECTION_MODAL_EVENT,
      name: 'Close',
      variant: 'MODAL_TILE_C',
    });

    onDismiss(PromptId.CreditProtection);
  };

  const onLearnMore = () => {
    trackClick({
      feature: CREDIT_PROTECTION_MODAL_EVENT,
      name: 'Learn More',
      variant: 'MODAL_TILE_C',
    });

    onDismiss(PromptId.CreditProtection);

    navigate('../details/credit-protection');
  };

  useEffect(() => {
    if (isOpen) {
      trackEvent({
        eventName: 'Modal Viewed',
        feature: CREDIT_PROTECTION_MODAL_EVENT,
        variant: 'MODAL_TILE_C',
      });
    }
  }, [isOpen]);

  return (
    <Modal
      headerText="Introducing Mission Lane Credit Protection"
      isOpen={isOpen}
      maxWidth="640px"
      primaryButton={{
        onPress: onLearnMore,
        text: 'Learn More',
      }}
      secondaryButton={{
        onPress: onClose,
        text: 'Close',
      }}
      onClose={onClose}
    >
      <div>
        <div
          className="flex items-center justify-center br2 mb4 mt4"
          style={{ height: '184px' }}
        >
          <img className="h-75" src={creditProtectionCardAndShield} />
        </div>
        <P3>
          Enrolling in Mission Lane Credit Protection can help with your card
          account payments if you experience certain financial hardships,
          including job loss, auto repairs, hospitalization, and medical bills.
        </P3>
      </div>
    </Modal>
  );
};

export default CreditProtectionModal;
