import { useEffect } from 'react';
import Body from '@clip/ClipProgress/components/ClipOffer/Guidance/Body/Body';
import Title from '@clip/ClipProgress/components/ClipOffer/Guidance/Title/Title';
import Footer from '@clip/ClipProgress/components/ClipOffer/Guidance/Footer/Footer';
import Kard from '@core/components/General/Kard/Kard';
import SeeOfferDetailsLink from '@clip/ClipProgress/components/ClipOffer/SeeOfferDetailsLink';
import { useTracking } from '@core/services/TrackService/useTracking';
import {
  CLIP_TRACKER_FEATURE_NAME,
  ClipEventNames,
} from '@clip/shared/lib/events';
import useClipOfferV2 from '@clip/ClipProgress/hooks/useClipOfferV2';

export const CLIP_OFFER_GUIDANCE_TEST_ID = 'CLIP_OFFER_GUIDANCE_TEST_ID';

const ClipOfferGuidance = () => {
  const { trackEvent } = useTracking();
  const clipOffer = useClipOfferV2();

  useEffect(() => {
    trackEvent({
      eventName: ClipEventNames.ViewKard,
      feature: CLIP_TRACKER_FEATURE_NAME,
      metadata: {
        clipOffer,
      },
    });
  }, []);

  return (
    <Kard className="mb3 w-100" testID={CLIP_OFFER_GUIDANCE_TEST_ID}>
      <div className="flex flex-column">
        <Title />
        <Body />
        <Footer />
        <SeeOfferDetailsLink />
      </div>
    </Kard>
  );
};

export default ClipOfferGuidance;
