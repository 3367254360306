import cx from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { gql, ApolloError, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Button,
  Notification,
  Spacer,
  Link as CompassLink,
  Spacing,
  Fonts,
  Colors,
  P3,
} from '@missionlane/compass-ui';

import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { HookFormTextField } from '../Form';
import GenericFallbackFull from '../GenericFallbacks/GenericFallbackFull';
import LoadingSpinner from '../General/LoadingSpinner';
import CardNameAndLast4Header from './ActivateCardNameAndLast4Header';
import { activationSchemaValidationGenerator } from './ActivationFormSchemaValidation';
import { CONTACT_US_LINK } from '@core/utils/constants';
import { GET_REPLACEMENT_CARD_BANNER_DATA_QUERY } from '@core/components/Banners/AuthenticatedBanners/ReplacementCardBanner';
import SupportPhoneLink from '@core/components/General/SupportPhoneLink';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import {
  ActivateCardMutation,
  ActivateCardMutationVariables,
  ActivationQuery,
  ActivationQueryVariables,
  IssuanceType,
} from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { useTracking } from '@core/services/TrackService/useTracking';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

export const ACTIVATION_QUERY = gql`
  query Activation($accountId: String!) {
    account(accountId: $accountId) {
      id
      isEligibleForActivation
      cardDetails {
        last4
        issuanceType
        cardLockStatus {
          isLocked
        }
      }
    }
  }
`;

export const ACTIVATE_CARD_MUTATION = gql`
  mutation ActivateCard(
    $accountId: String!
    $last4OfSSN: String!
    $last4OfCard: String!
    $dateOfBirth: String!
    $zipCode: String!
  ) {
    activateCard(
      accountId: $accountId
      last4OfSSN: $last4OfSSN
      last4OfCard: $last4OfCard
      dateOfBirth: $dateOfBirth
      zipCode: $zipCode
    ) {
      activationStatus
    }
  }
`;

type ActivationFormValues = {
  last4OfCard: string;
  last4OfSSN: string;
  zipCode: string;
  dob: {
    year: string;
    month: string;
    day: string;
  };
};

const ActivationForm = () => {
  const { isDesktopLarge } = useUserDevice();
  const { accountId, customerId } = useCustomerAndAccountIdentifiers();
  const navigate = useNavigate();
  const accountSummaryPath = useAccountSummaryPath();
  const { trackClick, trackError, trackPage, trackEvent } = useTracking();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [isFormSubmissionError, setIsFormSubmissionError] =
    useState<boolean>(false);
  const { loading, data, error } = useAccountIdQuery<
    ActivationQuery,
    ActivationQueryVariables
  >(ACTIVATION_QUERY, { fetchPolicy: 'cache-and-network' });
  const [activateCard] = useMutation<
    ActivateCardMutation,
    ActivateCardMutationVariables
  >(ACTIVATE_CARD_MUTATION, {
    // ensure the Replacement Card Banner is no longer served after this flow.
    refetchQueries: [
      {
        query: GET_REPLACEMENT_CARD_BANNER_DATA_QUERY,
        variables: { accountId },
      },
    ],
    awaitRefetchQueries: true,
  });
  const validationSchema = activationSchemaValidationGenerator(trackEvent);
  const methods = useForm<ActivationFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });
  const { handleSubmit, formState, getValues } = methods;
  const { errors: formErrors, isSubmitting, isValid } = formState;

  useEffect(() => {
    if (customerId) trackPage({ name: 'Activation' });
  }, [customerId]);

  const onCancel = () => {
    navigate(accountSummaryPath, { replace: true });
  };

  const isNewCard =
    data?.account?.cardDetails?.issuanceType === IssuanceType.New;

  const onSubmit = handleSubmit(async () => {
    const { last4OfCard, last4OfSSN, zipCode, dob } = getValues();

    const dateOfBirth = dayjs()
      .year(Number(dob.year))
      .month(Number(dob.month) - 1) // month is 0-indexed
      .date(Number(dob.day)); // date means day of month, not day of week

    if (!dateOfBirth.isValid()) {
      setIsFormSubmissionError(true);
      return;
    }
    try {
      const result = await activateCard({
        variables: {
          accountId: accountId || '',
          last4OfCard,
          last4OfSSN,
          zipCode,
          dateOfBirth: dateOfBirth.format('YYYY-MM-DD'),
        },
      });
      if (result.data?.activateCard.activationStatus !== 'APPROVED') {
        setIsFormSubmissionError(true);
      } else {
        // Approved activation, proceed to success screen
        navigate('success', {
          state: {
            prevPath: location.pathname,
            isNewCardActivation: isNewCard,
          },
        });
      }
    } catch (err) {
      setSubmitDisabled(false);
      if (err instanceof ApolloError) {
        trackEvent({
          eventName: 'Activate Card Form Error Message',
          name: 'Trouble Activating your Card (Service Error: Declined)',
        });
        setIsFormSubmissionError(true);
      } else {
        throw err;
      }
    }
  });

  const isEligibleForActivation = data?.account?.isEligibleForActivation;
  const isLocked = data?.account?.cardDetails?.cardLockStatus.isLocked;

  useEffect(() => {
    if (isEligibleForActivation === false) {
      if (isLocked) {
        trackError({
          name: 'Card is locked',
          feature: 'Activation',
          error: {
            name: 'Card is locked',
            message:
              'Your card is currently locked. To activate your card, please unlock your card and try again or call us at 855-790-8860',
            code: 'E013',
          },
        });
      } else {
        trackError({
          name: 'Card cannot be activated online',
          feature: 'Activation',
          error: {
            name: 'Card cannot be activated online',
            message:
              'Your card cannot be activated online at this time. To activate your card, please call us at 855-790-8860',
            code: 'E014',
          },
        });
      }
    }
  }, [isEligibleForActivation, isLocked]);

  if (error) {
    return <GenericFallbackFull />;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!isEligibleForActivation) {
    if (isLocked) {
      return (
        <Notification level="error">
          Your card is currently locked. To activate your card, please{' '}
          <Link
            to="../../lock-card"
            onClick={() =>
              trackClick({ name: 'Unlock your card', feature: 'Activation' })
            }
          >
            <CompassLink>unlock your card</CompassLink>
          </Link>{' '}
          and try again or call us at <SupportPhoneLink feature="Activation" />
        </Notification>
      );
    }
    return (
      <Notification level="error">
        Your card cannot be activated online at this time. To activate your
        card, please call us at <SupportPhoneLink feature="Activation" />
      </Notification>
    );
  }

  return (
    <>
      <CardNameAndLast4Header />
      <Spacer size="s" />
      <FormProvider {...methods}>
        <HookFormTextField
          name="last4OfCard"
          label="Last 4 of your new card"
          error={formErrors?.last4OfCard?.message}
          placeholder="XXXX"
          maxLength={4}
          keyboardType="number-pad"
          returnKeyType="next"
        />
        <Spacer size="s" />
        <HookFormTextField
          name="last4OfSSN"
          label="Last 4 of your social security number"
          maxLength={4}
          keyboardType="number-pad"
          returnKeyType="next"
          placeholder="XXXX"
          error={formErrors?.last4OfSSN?.message}
        />
        <Spacer size="s" />
        <HookFormTextField
          name="zipCode"
          maxLength={5}
          keyboardType="number-pad"
          label="Zip code"
          placeholder="XXXXX"
          returnKeyType="next"
          error={formErrors?.zipCode?.message}
        />
        <Spacer size="m" />
        {/* TODO: replace it with FieldLabel when it's correctly exported from compass-ui */}
        <P3
          style={{
            fontFamily: Fonts.semiBold,
            color: Colors.ink,
          }}
        >
          Date of birth
        </P3>
        <Spacer size="s" />

        <div
          className={cx('flex', !isDesktopLarge && 'flex-column')}
          style={{
            gap: Spacing.m,
          }}
        >
          <div className="w-100">
            <HookFormTextField
              name="dob.year"
              maxLength={4}
              keyboardType="number-pad"
              placeholder="YYYY"
              returnKeyType="next"
              error={formErrors?.dob?.year?.message}
            />
          </div>
          <div className="w-100">
            <HookFormTextField
              name="dob.month"
              maxLength={2}
              keyboardType="number-pad"
              placeholder="MM"
              returnKeyType="next"
              error={formErrors?.dob?.month?.message}
            />
          </div>
          <div className="w-100">
            <HookFormTextField
              name="dob.day"
              maxLength={2}
              keyboardType="number-pad"
              placeholder="DD"
              error={formErrors?.dob?.day?.message}
            />
          </div>
        </div>
        {isFormSubmissionError && <ActivationErrorPanel />}
        <div className="mt5 mb2 flex-ns justify-end" style={{ gap: Spacing.l }}>
          <span className="dn db-ns">
            <Button
              text="Cancel"
              onPress={onCancel}
              disabled={!isValid && submitDisabled}
              variant="text"
            />
          </span>
          <Button
            text="Activate"
            onPress={onSubmit}
            disabled={!isValid && submitDisabled}
            loading={isSubmitting}
          />
        </div>
      </FormProvider>
    </>
  );
};

export default ActivationForm;

const SupportLink = ({ children }: { children: ReactNode }) => (
  <a className="link blue hover-indigo" href={CONTACT_US_LINK}>
    {children}
  </a>
);

const ActivationErrorPanel = () => {
  return (
    <div className="mv3">
      <Notification variant="inline" level="error">
        We can&apos;t activate your card. To activate your card, please contact
        us <SupportLink>here</SupportLink>, or call us at{' '}
        <SupportPhoneLink className="blue hover-indigo link" /> Monday through
        Friday from 9am to 8pm ET.
      </Notification>
    </div>
  );
};
