import { gql, useQuery } from '@apollo/client';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { PageHeaderWithSignOut } from '../Page/PageHeader';
import { LoadingSpinnerPage } from '../General/LoadingSpinner';
import {
  activeAccountIdVar,
  resetActiveAccountId,
} from '../Navigation/NavContainer';
import { GenericFallbackFullPage } from '../GenericFallbacks/GenericFallbackFull';
import CreditCardsSection from './CreditCardsSection';
import { HomePageQuery } from '@core/graphql/globalTypes';
import PageWrapper from '@core/components/Page/PageWrapper';

export const HOME_PAGE_QUERY = gql`
  query HomePage {
    creditCards {
      id
    }
    customer {
      id
      hasPendingAccount
    }
  }
`;

const HomePage = () => {
  const { data, loading } = useQuery<HomePageQuery>(HOME_PAGE_QUERY, {
    errorPolicy: 'all',
  });
  const hasPendingAccount = Boolean(data?.customer?.hasPendingAccount);

  useEffect(() => {
    // Reset the last accountId cached by the sidebar nav if it
    // is still defined when we land on this page.
    // This prevents any potential data leaks when switching between accounts.
    if (activeAccountIdVar()) {
      resetActiveAccountId();
    }
  }, []);

  if (loading) {
    return <LoadingSpinnerPage centerContent withFooter showHeader />;
  }

  // If they only have one card, and no accounts pending creation in ECS, route them to the
  // the account summary page for their card
  if (data?.creditCards.length === 1 && !hasPendingAccount) {
    return (
      <Navigate to={`account/${data.creditCards[0].id}/summary`} replace />
    );
  }

  // If they have multiple cards, display an overview of those cards
  return (
    <>
      <PageHeaderWithSignOut centerContent />
      <PageWrapper
        centerContent
        withFooter
        trackingProperties={{ pageName: 'Account Selection' }}
      >
        <CreditCardsSection hasPendingCreditCard={hasPendingAccount} />
      </PageWrapper>
    </>
  );
};

export default HomePage;
