import cx from 'classnames';
import { Button } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { HeaderLogo } from './HeaderLogo';

interface PageHeaderProps {
  onMenuOpen?: () => void;
  centerContent?: boolean;
  hasVerticalPaddingOnly?: boolean;
}

export const PageHeader = ({
  onMenuOpen,
  centerContent = false,
  hasVerticalPaddingOnly = false,
}: PageHeaderProps) => (
  <div
    className={cx('flex mb2-ns', hasVerticalPaddingOnly ? 'pb4' : 'pa4', {
      'justify-between': !!onMenuOpen,
      'mw8 w-100 center': centerContent,
    })}
  >
    <HeaderLogo color="green" />
    {onMenuOpen && (
      <Button
        // @ts-ignore - TODO: Let compass accept colors here
        color="inkLight"
        variant="icon"
        icon="bars"
        accessibilityLabel="Open menu"
        onPress={onMenuOpen}
      />
    )}
  </div>
);

interface PageHeaderWithSignOutProps {
  centerContent?: boolean;
}

export const PageHeaderWithSignOut = ({
  centerContent,
}: PageHeaderWithSignOutProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={cx('flex pa3 pa4-ns mb2-ns justify-between items-center', {
        'mw8 w-100 center': centerContent,
      })}
    >
      <HeaderLogo color="green" />
      <Button
        size="small"
        variant="text"
        text="Sign out"
        onPress={() => navigate('/signout')}
      />
    </div>
  );
};
