import cx from 'classnames';
import { P3 } from '@missionlane/compass-ui';
import { centsToDollars } from '@core/utils/centsToDollars';
import { PaymentMethodName } from '@payments/components/Pay/Pay';
import { FundingAccounts } from '@core/graphql/globalTypes';
import AutopayListItem from '@payments/components/Autopay/AutopayListItem';
import { getUTC } from '@core/utils/timezones';

interface Props {
  amount: number | undefined;
  className?: string;
  isImmediate?: boolean;
  paymentDate?: string | null;
  fundingAccount?: FundingAccounts.FundingAccounts | null;
  paymentMethod?: PaymentMethodName;
  isPaymentSuccess?: boolean;
}

function getPaymentMethodNameString(name?: PaymentMethodName) {
  switch (name) {
    case PaymentMethodName.ACH:
      return 'Bank Account';
    case PaymentMethodName.DEBIT_CARD:
      return 'Debit Card';
    default:
      return 'Unknown Source';
  }
}

const PaymentDisplay = ({
  amount,
  className,
  paymentDate,
  fundingAccount,
  isImmediate,
  paymentMethod,
  isPaymentSuccess,
}: Props) => {
  const formattedPaymentDate =
    paymentDate ? getUTC(paymentDate).format('MMM DD, YYYY') : '';

  return (
    <div className={cx('pl3 bl bw1 f6 ink b--ink', className)}>
      <AutopayListItem label={isPaymentSuccess ? 'You Paid' : 'Amount'}>
        {/* @ts-ignore - TODO: Payments team to handle when null */}
        <P3>{centsToDollars(amount)}</P3>
      </AutopayListItem>
      <AutopayListItem label="From">
        <P3>
          {fundingAccount ?
            <>
              {fundingAccount.bankName}
              ...
              {fundingAccount.numberLast4}
            </>
          : getPaymentMethodNameString(paymentMethod)}
        </P3>
      </AutopayListItem>
      <AutopayListItem label="When" bottom>
        <P3 style={{ margin: 0 }}>
          {isImmediate ? 'Earliest Payment Date' : formattedPaymentDate}
        </P3>
      </AutopayListItem>
    </div>
  );
};

export default PaymentDisplay;
