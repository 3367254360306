import { useNavigate } from 'react-router-dom';
import { H4, List, ListItem } from '@missionlane/compass-ui';
import KardCopy from '@core/components/Kard/KardCopy';
import throwingMoney from '@core/assets/throwingMoney.svg';
import PlaidContainer from '@payments/components/Plaid/PlaidContainer';
import { FundingAccounts } from '@core/graphql/globalTypes';
import Kard from '@core/components/General/Kard/Kard';

interface Props {
  fundingAccounts: FundingAccounts.FundingAccounts[];
}

export const getContent = (
  fundingAccounts: FundingAccounts.FundingAccounts[],
) => {
  if (fundingAccounts.length) {
    return {
      heading: `Success! You've got a payment method on file.`,
      description: `We'll store your account info for safe and easy payments.`,
      showAddOptions: false,
    };
  }

  return {
    heading: 'Add a Payment Method',
    description: `While you wait, link the bank account you'll use to make future payments.`,
    showAddOptions: true,
  };
};

const OnBoardingAccount = ({ fundingAccounts }: Props) => {
  const content = getContent(fundingAccounts);
  const navigate = useNavigate();

  function navigateToAddAccount() {
    navigate('../add-bank-account', {
      state: {
        referrer: location.pathname,
      },
    });
  }

  function handleChange(id: string, openPlaidModal: () => void) {
    switch (id) {
      case 'plaidAccount':
        openPlaidModal();
        break;
      case 'bankAccount':
        navigateToAddAccount();
        break;
      default:
        break;
    }
  }
  return (
    <PlaidContainer>
      {(openPlaidModal) => (
        <Kard className="relative mb5">
          <div>
            <div className="mv4-ns mv5" style={{ marginRight: '3.25rem' }}>
              <H4>{content.heading}</H4>
            </div>
            <img
              className="absolute right-0 top-1"
              src={throwingMoney}
              alt="autopay"
            />
          </div>
          <KardCopy>
            <p className="mb4">{content.description}</p>
            {content.showAddOptions && (
              <List
                variant="selectable"
                onChange={(id) => handleChange(id, openPlaidModal)}
              >
                <ListItem
                  id="plaidAccount"
                  label="Fast Add"
                  description="Connect your account through Plaid in just a few steps."
                  iconProps={{
                    icon: 'dollarSign',
                    iconColor: 'blue',
                    rightIcon: 'forward',
                    rightIconColor: 'blue',
                  }}
                />
                <ListItem
                  id="bankAccount"
                  label="Manual Add"
                  description="Connect your account with routing and account numbers."
                  iconProps={{
                    icon: 'dollarSign',
                    iconColor: 'blue',
                    rightIcon: 'forward',
                    rightIconColor: 'blue',
                  }}
                />
              </List>
            )}
          </KardCopy>
        </Kard>
      )}
    </PlaidContainer>
  );
};
export default OnBoardingAccount;
