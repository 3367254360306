import { useNavigate, useParams } from 'react-router-dom';
import {
  ReplacementMailedContent,
  ReplacementDelayedContent,
  ReplacementReturnedContent,
} from './ReplacementCardModalContent';
import {
  getModalPrimaryButtonByIssuanceState,
  mapIssuanceStateToModalHeader,
} from './helpers';
import Modal from '@core/components/General/Modal/Modal';
import { IssuanceState } from '@core/graphql/globalTypes';

type ModalContentProps = Pick<
  ReplacementCardModalProps,
  'issuanceState' | 'isChargedOff'
>;

const Content = ({ issuanceState, isChargedOff }: ModalContentProps) => {
  switch (issuanceState) {
    case IssuanceState.PreMailed:
    case IssuanceState.Mailed:
      return <ReplacementMailedContent />;
    case IssuanceState.Delayed:
      return <ReplacementDelayedContent isChargedOff={!!isChargedOff} />;
    case IssuanceState.Returned:
      return <ReplacementReturnedContent isChargedOff={!!isChargedOff} />;
    case undefined:
      throw new Error('Failed to fetch card issuanceState');
    default:
      throw new Error(
        `Card issuance state: ${issuanceState} is not handled in ReplacementCardModal`,
      );
  }
};

export interface ReplacementCardModalProps {
  issuanceState: IssuanceState | undefined;
  isChargedOff: boolean | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const ReplacementCardModal = ({
  issuanceState,
  isChargedOff,
  isOpen,
  onClose,
}: ReplacementCardModalProps) => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const headerText = mapIssuanceStateToModalHeader(issuanceState);
  const primaryButton = getModalPrimaryButtonByIssuanceState(issuanceState, {
    handleNavigateToActivationForm: () => {
      const navigateTo =
        accountId ? `/account/${accountId}/manage-card/activate` : '/activate';
      navigate(navigateTo);
    },
    handleClose: onClose,
  });

  return (
    <Modal
      headerText={headerText}
      primaryButton={primaryButton}
      isOpen={isOpen}
      onClose={onClose}
      accessibilityContentLabel="Replacement Card Modal"
    >
      <Content issuanceState={issuanceState} isChargedOff={isChargedOff} />
    </Modal>
  );
};

export default ReplacementCardModal;
