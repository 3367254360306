import { Link } from '@missionlane/compass-ui';
import { useState } from 'react';
import OfferDetailsModal from '@clip/ClipProgress/components/OfferDetailsModal/OfferDetailsModal';
import { ClipOfferStatusCodes } from '@clip/ClipProgress/hooks/types/ClipOffer';
import useClipOfferV2 from '@clip/ClipProgress/hooks/useClipOfferV2';
import { useTracking } from '@core/services/TrackService/useTracking';
import {
  CLIP_TRACKER_FEATURE_NAME,
  ClipEventNames,
} from '@clip/shared/lib/events';

const SeeOfferDetailsLink = () => {
  const clipOffer = useClipOfferV2();
  const [modalOpen, setModalOpen] = useState(false);
  const { trackClick } = useTracking();

  const trackOfferDetailsModal = (isModalOpen: boolean) => {
    trackClick({
      name:
        isModalOpen ?
          ClipEventNames.ClickCloseOfferDetails
        : ClipEventNames.ClickSeeOfferDetails,
      feature: CLIP_TRACKER_FEATURE_NAME,
    });
  };

  const toggleModal = () => {
    trackOfferDetailsModal(modalOpen);

    setModalOpen(!modalOpen);
  };

  if (
    clipOffer?.clipStatus === ClipOfferStatusCodes.APPROVED ||
    clipOffer?.clipStatus === ClipOfferStatusCodes.OVERLIMIT ||
    clipOffer?.clipStatus === ClipOfferStatusCodes.INELIGIBLE ||
    clipOffer?.clipStatus === ClipOfferStatusCodes.MISSED_PAYMENT ||
    clipOffer?.isInGracePeriod
  ) {
    return null;
  }

  return (
    <>
      <Link
        style={{
          textDecorationLine: 'none',
          fontWeight: 'bold',
        }}
        onPress={toggleModal}
      >
        More Details
      </Link>
      <OfferDetailsModal isOpen={modalOpen} onClose={toggleModal} />
    </>
  );
};

export default SeeOfferDetailsLink;
