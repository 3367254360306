import { useMutation, useQuery } from '@apollo/client';
import { B, Button, Link, P4 } from '@missionlane/compass-ui';
import { createRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import { useAccount } from '../Auth/AccountContext';
import Checkbox from '../General/Checkbox/Checkbox';
import LoadingSpinner from '../General/LoadingSpinner';
import ResponsiveTypography from '../General/ResponsiveTypography';
import { opportunityTilesQuery } from '../OpportunityTileCarousel/opportunityTilesQuery';
import CreditProtectionFAQModal from './CreditProtectionFAQModal';
import { ENROLL_IN_CREDIT_PROTECTION } from './enrollInCreditProtectionMutation';
import {
  GET_ACCOUNT_DETAILS_QUERY,
  GetAccountDetailsQuery,
} from '@clip/ClipProgress/network';
import {
  CreditProtectionEnrollmentChannel,
  GetAccountDetailsQueryQueryVariables,
} from '@core/graphql/globalTypes';
import {
  EnrollInCreditProtectionMutationVariables,
  EnrollInCreditProtectionMutation,
} from '@core/graphql/globalTypes';
import { useTracking } from '@core/services/TrackService/useTracking';
import { withPageWrapper } from '@core/utils/hoc/withPageWrapper';

const FEATURE_NAME = 'Credit Protection Enrollment Page';
const FAQ_MODAL_FEATURE_NAME = 'Credit Protection FAQ Modal';

const CreditProtectionPage = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { trackEvent, trackClick } = useTracking();
  const contentRef = createRef<HTMLDivElement>();
  const { accountId } = useAccount();
  const [searchParams] = useSearchParams();

  const enrollmentChannel =
    searchParams.get('utm_medium') === 'EMAIL' ?
      CreditProtectionEnrollmentChannel.Emlc
    : CreditProtectionEnrollmentChannel.Dash;

  const [
    enrollInCreditProtection,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<
    EnrollInCreditProtectionMutation,
    EnrollInCreditProtectionMutationVariables
  >(ENROLL_IN_CREDIT_PROTECTION, {
    refetchQueries: [opportunityTilesQuery],
  });

  const { data, loading, error } = useQuery<
    GetAccountDetailsQuery,
    GetAccountDetailsQueryQueryVariables
  >(GET_ACCOUNT_DETAILS_QUERY, {
    skip: !accountId,
    variables: { accountId },
  });

  const cardLast4 = data?.accountDetails?.cardLast4;
  const navigate = useNavigate();

  const onClose = () => {
    setIsOpen(false);

    trackEvent({
      eventName: `${FAQ_MODAL_FEATURE_NAME}: Close`,
      feature: FAQ_MODAL_FEATURE_NAME,
    });
  };

  const onOpen = () => {
    setIsOpen(true);

    trackEvent({
      eventName: `${FAQ_MODAL_FEATURE_NAME}: Open`,
      feature: FAQ_MODAL_FEATURE_NAME,
    });
  };

  const onCheckboxChange = () => {
    setIsChecked(!isChecked);

    trackEvent({
      eventName: `${FEATURE_NAME} checkbox open: ${isChecked}`,
      feature: FEATURE_NAME,
    });
  };

  const onCancelClicked = () => {
    trackClick({
      name: 'Cancel: Clicked',
      feature: FEATURE_NAME,
    });

    navigate(`/account/${accountId}/summary`);
  };

  const onSubmitClicked = async () => {
    if (!contentRef.current?.innerHTML) return; // unlikely to happen, just here to satisfy TS

    const result = await enrollInCreditProtection({
      variables: {
        snapshotContent: contentRef.current.innerHTML,
        enrollmentChannel: enrollmentChannel,
        accountId,
      },
    });

    if (mutationError || !result.data?.enrollInCreditProtection?.success) {
      trackEvent({
        eventName: `${FEATURE_NAME}: Error enrolling`,
        feature: FEATURE_NAME,
        error: mutationError,
      });

      return;
    }

    trackClick({
      name: 'Enroll in Credit Protection: Clicked',
      feature: FEATURE_NAME,
    });

    navigate('./success');
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || mutationError) throw error ?? mutationError;

  return (
    <>
      <div ref={contentRef}>
        <ResponsiveTypography desktopLevel="H3" mobileLevel="H2" type="HEADER">
          Introducing Mission Lane Credit Protection
        </ResponsiveTypography>
        <div className="mv3">
          <ResponsiveTypography
            desktopLevel="P3"
            mobileLevel="P1"
            type="PARAGRAPH"
          >
            <B color="ink">Enrolling in Mission Lane Credit Protection</B> can
            help with your card account payments if you experience certain
            unexpected life events in the future.
          </ResponsiveTypography>
          <div>
            <ResponsiveTypography
              desktopLevel="P3"
              mobileLevel="P1"
              type="PARAGRAPH"
            >
              <ul>
                <li>
                  Covered events include job loss, auto repairs,
                  hospitalization, and medical bills.
                </li>
                <li>
                  The fee for this service is{' '}
                  <B color="ink">
                    1.49% of your balance (shown as New Balance on your monthly
                    statement).
                  </B>
                </li>
                <li>
                  Coverage applies to the card you sign up with (account ending
                  in {cardLast4}).
                </li>
                <li>
                  Coverage requires enrollment and applies only to future
                  events.
                </li>
                <li>You can cancel at any time.</li>
              </ul>
            </ResponsiveTypography>
            <ResponsiveTypography
              desktopLevel="P3"
              mobileLevel="P1"
              type="PARAGRAPH"
            >
              <Link onPress={onOpen}>
                Learn more about the limitations and details of Mission Lane
                Credit Protection.
              </Link>
            </ResponsiveTypography>
            <div className="ba bw1 br2 mv3 b--washed-ink pa2">
              <iframe
                src="https://cdn.missionlane.com/docs/cp-t-c"
                style={{ border: 'none' }}
                width="100%"
              />
            </div>
            <ResponsiveTypography
              desktopLevel="P3"
              mobileLevel="P1"
              type="PARAGRAPH"
            >
              <B color="ink">Would you like to enroll in Credit Protection?</B>
            </ResponsiveTypography>
            <div className="ba bw1 br2 mv3 b--washed-ink pa3 flex flex-row">
              <Checkbox
                id="CreditProtectionChecked"
                name="CreditProtectionChecked"
                onChange={onCheckboxChange}
              />
              <div className="pl3">
                <ResponsiveTypography
                  color="ink"
                  desktopLevel="P3"
                  mobileLevel="P1"
                  type="PARAGRAPH"
                >
                  Yes, protect my card account ending in {cardLast4}.
                </ResponsiveTypography>
                <P4>
                  I have read, understand and agree to the Terms and Conditions
                  of Credit Protection.
                </P4>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="mr2">
                <Button
                  disabled={!isChecked}
                  loading={mutationLoading}
                  text="Enroll in Credit Protection"
                  variant="contained"
                  onPress={onSubmitClicked}
                />
              </div>
              <Button text="Cancel" variant="text" onPress={onCancelClicked} />
            </div>
          </div>
        </div>
      </div>
      <CreditProtectionFAQModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

const WrappedCreditProtectionPage = withPageWrapper(CreditProtectionPage, {
  trackingProperties: { pageName: FEATURE_NAME },
});

export default WrappedCreditProtectionPage;
