import { List, ListItem, P3, Notification } from '@missionlane/compass-ui';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

import {
  MakePaymentPaymentDateId,
  MakePaymentPaymentType,
  PaymentDateId,
  PaymentValue,
} from './types';
import {
  PaymentType,
  ActivityState,
  MakePayment,
  UpcomingPayments,
} from '@core/graphql/globalTypes';
import { useTracking } from '@core/services/TrackService/useTracking';
import { getET } from '@core/utils/timezones';
import { dateStringFormat as datePickerStringFormat } from '@payments/components/DatePicker';
import PaymentOutageMessage from '@payments/components/MakePayment/PaymentOutageMessage';
import paymentAlreadyScheduled from '@payments/components/MakePayment/utils/paymentAlreadyScheduled';
import PaymentDateModal from '@payments/components/PaymentDateModal';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import isPastDate from '@payments/utils/isPastDate';

const displayFormat = 'dddd, MMMM D, YYYY';

const datePickerHelperText = (isPayingCustomAmount: boolean) => (
  <>
    {!isPayingCustomAmount && (
      <P3>
        You can schedule a payment up to 90 days from today&rsquo;s date using
        the &lsquo;Pay another amount&rsquo; option.
      </P3>
    )}
  </>
);

const hasMadeMaxPayments = (
  upcomingPayments: UpcomingPayments.UpcomingPayments[],
) => upcomingPayments.length >= 3;

const maxPaymentsError =
  "You've already scheduled the maximum of 3 future payments.";

interface Props {
  showWarning: boolean;
  customPaymentDate: string | null;
  immediatePaymentDate: string;
  selected: MakePaymentPaymentDateId;
  amountType: MakePaymentPaymentType;
  paymentInfo: MakePayment.PaymentInfo;
  onChange: (selected: MakePaymentPaymentDateId, date: string | null) => void;
  onCustomDateChange: (date: string) => void;
  upcomingPayments?: UpcomingPayments.UpcomingPayments[];
  className?: string;
  showPaymentsOutage?: boolean;
  isMinDueSchedulePrompt?: boolean;
}

const PaymentDate = ({
  showWarning,
  customPaymentDate,
  immediatePaymentDate,
  selected = 'immediately',
  amountType,
  paymentInfo,
  onChange,
  onCustomDateChange,
  upcomingPayments,
  className,
  showPaymentsOutage,
  isMinDueSchedulePrompt,
}: Props) => {
  const { showHcrExperience } = useFlags();
  const { isChargedOff, autopay } = usePaymentStatus();
  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);
  const { trackError } = useTracking();

  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(
    Boolean(isMinDueSchedulePrompt),
  );

  // TODO use Formik for the form state and get rid of this state
  const [map, setMap] = useState<
    Record<PaymentDateId, PaymentValue<string | null>>
  >({
    immediately: {
      value: immediatePaymentDate,
      display: dayjs(immediatePaymentDate).format(displayFormat),
    },
    earliest: {
      value: immediatePaymentDate,
      display: dayjs(immediatePaymentDate).format(displayFormat),
    },
    scheduledDate: {
      value: customPaymentDate,
      display:
        customPaymentDate ? dayjs(customPaymentDate).format(displayFormat) : '',
    },
  });

  const chargedOffExperience = isChargedOff && showHcrExperience;

  useEffect(() => {
    if (selected) handleSelectionChanged(selected);
  }, []);

  useEffect(() => {
    revalidate();
  }, [amountType]);

  useEffect(() => {
    if (immediatePaymentDate !== map.immediately.value) {
      resetImmediatePaymentDate();
    }
  }, [immediatePaymentDate]);

  useEffect(() => {
    if (
      (showPaymentsOutage && selected === PaymentDateId.immediately) ||
      (!showPaymentsOutage && selected === PaymentDateId.earliest)
    ) {
      // if an outage has started or ended, clear the payment date values so the user
      // cannot accidentally schedule a payment for an undesired date
      onChange(null, null);
    }
  }, [showPaymentsOutage, selected]);

  function resetImmediatePaymentDate() {
    setMap((prevMap) => ({
      ...prevMap,
      immediately: {
        value: immediatePaymentDate,
        display: dayjs(immediatePaymentDate).format(displayFormat),
      },
      earliest: {
        value: immediatePaymentDate,
        display: dayjs(immediatePaymentDate).format(displayFormat),
      },
    }));
  }

  function revalidate() {
    if (selected) {
      const date = map[selected].value;
      const validatedDate = date && validateDate(date) ? date : '';

      onChange(selected, validatedDate);
    }
  }

  function handleSelectionChanged(
    id: `${PaymentDateId}` | 'select-another-date',
  ) {
    if (id.includes('select-another-date') || id === 'select-another-date') {
      handleSelectDateClicked();

      return;
    }

    let date = map[id] ? map[id].value : '';

    if (date && !validateDate(date)) {
      date = null;
    }

    onChange(id, date);
  }

  function handleDatePickerDateChanged(date: string) {
    // Update the errors but don't block anything if we fail validation
    validateDate(date);

    setMap((prevMap) => ({
      ...prevMap,
      scheduledDate: {
        value: date,
        display: getET(date).format(displayFormat),
      },
    }));

    onCustomDateChange(date);

    onChange(PaymentDateId.scheduledDate, date);

    selected = PaymentDateId.scheduledDate;
  }

  function handleSelectDateClicked() {
    if (!validateShowDatePicker() || showWarning) {
      return;
    }

    toggleDatePicker();
  }

  function toggleDatePicker() {
    setIsDatePickerOpen((prevValue) => !prevValue);
  }

  function validateDate(date: string) {
    const newErrors = dateValidationErrors(date);
    const newWarnings = dateValidationWarnings(date);

    setErrors(newErrors);

    setWarnings(newWarnings);

    return !newErrors.length;
  }

  function dateValidationErrors(date: string) {
    const newErrors = [];
    const upcomingPaymentsList = upcomingPayments || [];

    if (
      paymentAlreadyScheduled(
        dayjs(date).format('YYYY-MM-DD'),
        upcomingPaymentsList,
      )
    ) {
      newErrors.push("You've already scheduled a payment for that date.");

      trackError({
        name: 'Payment Already Scheduled',
        feature: 'Schedule Payment',
        metadata: {
          amountType,
          paymentInfo,
          selectDate: date,
        },
        error: {
          code: 'PAY0000',
          message: "You've already scheduled a payment for that date.",
          name: 'Payment Already Scheduled',
        },
      });
    }

    return newErrors;
  }

  function dateValidationWarnings(date: string) {
    const selectedDate = getET(date);

    const hasWarning =
      paymentInfo.minimumDue > 0 &&
      !getET(immediatePaymentDate).isSame(selectedDate) &&
      // @ts-ignore TODO: Payments team should handle when this printDueDate is null
      isPastDate(date, paymentInfo.printDueDate) &&
      !chargedOffExperience;

    const nextAutopay = autopay && autopay[0];

    const nextAutopayDate =
      nextAutopay?.nextPaymentDate ? getET(nextAutopay.nextPaymentDate) : null;

    const paymentDueDate =
      paymentInfo?.printDueDate ? getET(paymentInfo.printDueDate) : null;

    const autopayCoversPayment =
      nextAutopayDate &&
      paymentDueDate &&
      selectedDate.isAfter(paymentDueDate) &&
      nextAutopayDate.isSameOrBefore(paymentDueDate);

    if (hasWarning && !autopayCoversPayment && selected !== 'immediately') {
      return [
        'This scheduled date is past your due date, so you’ll still need to make your minimum payment to avoid additional fees or finance charges.',
      ];
    }

    return [];
  }

  function validateShowDatePicker() {
    const newWarnings = [];

    const scheduledPayments = (upcomingPayments || []).filter(
      (payment) => payment.state === ActivityState.Scheduled,
    );

    if (hasMadeMaxPayments(scheduledPayments)) {
      newWarnings.push(maxPaymentsError);
    }

    setWarnings(newWarnings);

    return !newWarnings.length;
  }

  function futureDateByDays(days: number) {
    const futureDate = getET().add(days, 'd').format(datePickerStringFormat);

    return futureDate;
  }

  const FIRST_SCHEDULED_PAYMENT_DATE = futureDateByDays(1);
  const LAST_SCHEDULED_PAYMENT_DATE = futureDateByDays(90);

  return (
    <div className={className}>
      {showWarning && (
        <Notification level="warning">
          Some options are currently unavailable. We apologize for the
          inconvenience.
        </Notification>
      )}
      <List
        selected={selected ? selected : ''}
        variant="selectable"
        onChange={handleSelectionChanged}
      >
        {!isMinDueSchedulePrompt &&
          (!showPaymentsOutage ?
            <ListItem
              description={map.immediately.display}
              id="immediately"
              label="Earliest Payment Date"
              iconProps={{
                icon: 'calendar',
                iconColor: 'green',
              }}
            />
            // If there's a payments outage, we allow the user to schedule a payment for tomorrow instead
          : <ListItem
              description={map.earliest.display}
              id="earliest"
              label="Earliest Date"
              iconProps={{
                icon: 'calendar',
                iconColor: 'green',
              }}
            />)}
        {map.scheduledDate.value && (
          <ListItem
            description={map.scheduledDate.display}
            id="scheduledDate"
            label="Scheduled Date"
            iconProps={{
              icon: 'calendar',
              iconColor: 'green',
            }}
          />
        )}
        <ListItem
          colorTheme="blue"
          disabled={showWarning}
          iconProps={{ icon: 'calendar' }}
          id={`select-another-date${showWarning ? '--disabled' : ''}`}
          label="Select another date"
        />
      </List>
      <div className="mt2">
        {warnings.map((warning, index) => (
          <div key={index} className="mb2">
            <Notification level="warning" variant="inline">
              {warning}
            </Notification>
          </div>
        ))}
        {errors.map((error, index) => (
          <div key={index} className="mb2">
            <Notification level="error" variant="inline">
              {error}
            </Notification>
          </div>
        ))}
      </div>
      <p className="db ink-50 mv2 sans-serif">
        Payments received after 5:59 p.m. ET will be credited as of the next
        day.
      </p>
      {/* TODO: Refactor this to use @core/components/General/Datepicker */}
      <PaymentDateModal
        dateValidator={dateValidationErrors}
        invalidDates={upcomingPayments?.map((payment) => payment.date)}
        isOpen={isDatePickerOpen}
        max={LAST_SCHEDULED_PAYMENT_DATE}
        min={FIRST_SCHEDULED_PAYMENT_DATE}
        printDueDate={paymentInfo.printDueDate}
        selectedDate={dayjs(map.scheduledDate.value).unix()}
        startDate={FIRST_SCHEDULED_PAYMENT_DATE}
        helperText={datePickerHelperText(
          amountType === PaymentType.OneTimeFixed,
        )}
        onChange={handleDatePickerDateChanged}
        onClose={toggleDatePicker}
      />
      <PaymentOutageMessage />
    </div>
  );
};

export default PaymentDate;
