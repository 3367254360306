import { gql } from '@apollo/client';

import AUTOPAY_FIELDS from '@payments/graphql/AutopayFieldsFragment';
import PAYMENT_FIELDS from '@payments/graphql/PaymentFieldsFragment';

export const UPCOMING_PAYMENTS_QUERY = gql`
  query UpcomingPayments($accountId: String!) {
    account(accountId: $accountId) {
      id
      upcomingPayments {
        ...PaymentFields
      }
      balanceInfo {
        currentBalance
      }
    }
  }
  ${PAYMENT_FIELDS}
`;

export const MAKE_PAYMENT_QUERY = gql`
  query MakePayment($accountId: String!) {
    customer {
      id
    }
    account(accountId: $accountId) {
      id
      paymentInfo {
        minimumDue
        printDueDate
        nextStatementCloseDate
      }
      cardDetails {
        last4
        name
        last4
      }
      autopay {
        ...AutopayFields
      }
      reAge {
        reAgeQualificationAmount {
          value
          currency
        }
        reAgeTotalPaymentsMade {
          value
          currency
        }
      }
      statuses {
        isPastDue
        isBankrupt
        isPaymentPlanEligible
        isChargedOff
        pastDueStatus
        isDebitEligible
      }
    }
  }
  ${AUTOPAY_FIELDS}
`;

export const GET_TRANSACTION_BY_ID = gql`
  query Payment($reference: String!, $accountId: String!) {
    payment(reference: $reference, accountId: $accountId) {
      id
      state
    }
  }
`;

export const BALANCE_INFO_QUERY = gql`
  query BalanceInfo($accountId: String!) {
    account(accountId: $accountId) {
      id
      balanceInfo {
        currentBalance
        remainingStatementBalance
        statementBalance
      }
    }
  }
`;
