import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import AddBankAccountBanner from '../BankAccount/AddBankAccount/AddBankAccountBanner';
import { MAKE_PAYMENT_QUERY } from './gql/queries';
import PaymentForm from './PaymentForm';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';

import './MakePayment.css';
import PageWrapper from '@core/components/Page/PageWrapper';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import {
  MakePaymentQuery,
  MakePaymentQueryVariables,
} from '@core/graphql/globalTypes';
import { MLError } from '@core/services';

const MakePayment = () => {
  const { showPaymentsOutage } = useFlags();
  const { data, loading } = useAccountIdQuery<
    MakePaymentQuery,
    MakePaymentQueryVariables
  >(MAKE_PAYMENT_QUERY);

  const { account } = data || {};
  const {
    id,
    paymentInfo,
    cardDetails,
    autopay = [],
    statuses,
    reAge,
  } = account || {};

  useEffect(() => {
    if (autopay.length > 1) {
      MLError.report({ name: `Account ${id} has more than one autopay` });
    }
  }, [autopay.length]);

  if (loading) return <LoadingSpinnerPage />;

  return (
    <PageWrapper
      pageTitle={{ primaryText: 'Make a Payment', useLast4: true }}
      banners={[AddBankAccountBanner]}
      trackingProperties={{
        pageName: 'Make a Payment: Pay with Bank Account',
        featureName: 'Payments: Make Payment',
      }}
    >
      <div className="sans-serif lh-copy">
        <PaymentForm
          cardDetails={cardDetails}
          paymentInfo={paymentInfo}
          autopay={autopay[0]}
          loading={loading}
          showPaymentsOutage={showPaymentsOutage}
          statuses={statuses}
          reAge={reAge}
        />
      </div>
    </PageWrapper>
  );
};

export default MakePayment;
