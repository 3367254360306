import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { LoadingSpinnerPage } from '../../General/LoadingSpinner';
import { CustomerIO, TrackService } from '@core/services';

/*
  will redirect to /signin when idToken is cleared from okta-react via local storage
  a call to signin.missionlane.com/login/signout will not work since it only logs out on the org level:
  https://devforum.okta.com/t/logout-case-sensitive/10690/8
*/
const Logout = () => {
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    TrackService.page('Logout');
    oktaAuth.signOut();
    CustomerIO.reset();
  }, []);

  return <LoadingSpinnerPage centerContent withFooter showHeader />;
};

export default Logout;
